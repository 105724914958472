import React, { useState } from "react";
import "./authStyle.scss";
import logo from "../../assets/images/logo.png";
import CustomButton from "../../components/Button/Button";
import notify from "./../../shared/notify";
import CustomInput from "../../components/CustomInput";
import { useStores } from "./../../stores";
import ValidateEmail from "../../Helper/emailValidation";
import { useNavigate } from "react-router-dom";
import service from "../../shared/service/service";
import Loader from "../../Helper/Loader";
import ReCAPTCHA from "react-google-recaptcha";

const Login = (props) => {
  const navigate = useNavigate();
  const { mainStore } = useStores();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [isLoading, setIsLoading] = useState(false)
  const [isVerified, setIsVerified] = useState(false)


  const handleLoginButton = (e) => {
    if (e.key === "Enter") {
      login()
    }
  }

  const login = async () => {
    if (email === "undefined" || email === "" || email === "null") {
      return notify("Please Enter Email", "danger");
    }
    if (password === "undefined" || password === "" || password === "null") {
      return notify("Please Enter Password", "danger");
    }
    setIsLoading(true)

    let payload = {
      email: email,
      password: password,
    };
    setIsLoading(true)
    await mainStore.loginAPI(
      payload,
      (response) => {
        if (response?.data?.meta?.status === "success") {
          if (response?.data?.data?.user?.roles?.includes("Admin")) {
            sessionStorage.setItem("token", response?.data?.data?.token);
            sessionStorage.setItem(
              "userData",
              JSON.stringify(response?.data?.data?.user)
            );
            notify(response?.data?.meta?.message);
            window.location.reload();
            setIsLoading(false)
          } else {
            notify("Don't have permission to login", "danger");
            setEmail("");
            setPassword("");
            setIsLoading(false)
          }
        } else {
          notify(response?.data?.meta?.message, "danger");
          setIsLoading(false)
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        setIsLoading(false)
      }
    );
    service.refreshHeader(() => { });
  };

  const handleEmailOnBlur = (e) => {
    let validate = ValidateEmail(e);
    if (validate === false) {
      notify("Please Enter A Valid Email ID", "danger");
      setEmail("");
      return;
    } else {
      setEmail(e);
    }
  };

  const handleExpire = () => {
    setIsVerified(false);
  };
  const handleVerify = () => {
    setIsVerified(true);
  };

  const handleCaptcha = () => {
    notify("please validate captcha", "danger")
  }

  return (
    <div className="login-mainContainer">
      {isLoading && <Loader />}
      <div className="login-container">
        <div className="login-box">
          <img src={logo} className="logo-style" alt="" />
          <div>
            <h2 className="login-heading">Log in to SAEL Admin</h2>
            <div className="heading-underline"></div>
          </div>
          <div className="row login-dashboard">
          <div className="col-md-12 login-text mt-4">
            <CustomInput
              label={"Email"}
              placeholder={"Enter Email"}
              onBlur={(e) => {
                handleEmailOnBlur(e.target.value);
              }}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            </div>

           

            <div className="col-md-12 login-text" style={{ position: "relative" }}>
            <CustomInput
                label={"Password"}
                placeholder={"Enter Password"}
                type={passwordType}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                onKeyDown={handleLoginButton}
              />
                 <div className="password-view">
                {passwordType == "password" ? (
                  <img
                    onClick={() => {
                      setPasswordType("text");
                    }}
                    width={28}
                    src={require("../../assets/images/hide.png")}
                  />
                ) : (
                  <img
                    width={28}
                    onClick={() => {
                      setPasswordType("password");
                    }}
                    src={require("../../assets/images/view.png")}
                  />
                )}
              </div>
                </div>

            <div className=" col-md-12 text-center  mt-4">
              <ReCAPTCHA
                sitekey="YOUR_RECAPTCHA_SITE_KEY"
                onChange={handleVerify}
                onExpired={handleExpire}
              />
            </div>


            <div className="col-md-12 text-center  mt-4">
              {/* <CustomButton styles={{ width: 140, height: 38, fontSize: 16, }} onClick={() => isVerified ? login() : handleCaptcha()} children={"Log In"} /> */}
              <CustomButton styles={{ width: 140, height: 38, fontSize: 16, }} onClick={() => login()} children={"Log In"} />
            </div>
          </div>
          </div>
        </div>
    </div>
  );
};

export default Login;


