import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import CustomCard from "../../components/CustomCard";
import "./index1.scss";
import CustomInput from "../../components/CustomInput";
import CustomButton from "../../components/Button/Button";
import TextEditor from "../../components/CustomeTextEditior";
import Textarea from "../../components/CustomeTextarea";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import commentImg from '../../assets/images/comment-img.png';
import commentImg1 from '../../assets/images/comment1-img.png';
import { useLocation, useNavigate } from "react-router-dom"
import FormatDate from "../../Helper/formateDate";
import notify from "../../shared/notify";
import { useStores } from "../../stores";
import * as XLSX from 'xlsx';
import excelIcon from '../../assets/images/excel.png';




const ViewTickets = () => {
    const { state } = useLocation();
    console.log(state, "admmmmm");

    const navigate = useNavigate()
    const [auditTrail, setAuditTrail] = useState([])
    const [showAll, setShowAll] = useState(false);
    const { mainStore } = useStores()



    const toggleVisibility = () => {
        setShowAll(prevState => !prevState);
    };
    const dataToDisplay = showAll ? auditTrail : [auditTrail[auditTrail.length - 1]]


    useEffect(() => {
        fetchAuditTrail()
    }, [])

    const fetchAuditTrail = async () => {
        await mainStore.auditTrailCms(state?.item?.complaint_id, (status, res) => {
            if (res?.meta?.status == "success") {
                setAuditTrail(res?.data?.auditTrailRecords)
            } else {
                console.log(res?.meta?.message, "error");

            }
        }, (error) => {
            notify(error?.response?.meta?.message, "danger")
        })
    }

    const getFormattedDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames[date.getMonth()];
        const day = date.getDate();

        return `${month} ${day}, ${year}`;
    };

    const exportToExcel = () => {
        const dataToExport = auditTrail?.map((item, i) => ({
            index: i + 1,
            role: item?.role,
            created_at: item?.created_at && `${item.created_at.split("T")[0]} ${item.created_at.split("T")[1]?.split(".")[0].substring(0, 5)}`,
            reporter_name: item?.reporter_name,
            support_level: item?.support_level,
            status: item?.status,
            comments: item?.comments,
        }));
        const worksheet = XLSX.utils.json_to_sheet(dataToExport);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'AuditTrailData.xlsx');
    };


    return (
        <div className="main-container raiseTicket_container_crm">
            <div className="right-view">
                <Header title={"View Ticket,"} />
                <div className="container-fluid px-0">
                    <div className="row">
                        <div className="col-md-12">

                            <CustomCard
                                cardtitle={"Ticket"}
                                cardboldtitle={"Details"}
                                cardbody={
                                    <>
                                        <img src={require("../../assets/images/left-arrow.png")} className="back-btn-dashboard" onClick={() => navigate("/view-all-ticket")} />
                                        <div className="ticket_detaile_box">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Textarea label={'Summary'}
                                                        style={''}
                                                        readOnly
                                                        require
                                                        // defaultValue
                                                        title={state?.item?.issue_summary} />

                                                </div>
                                                <div className="col-md-12">
                                                    <TextEditor
                                                        label={'Description'}
                                                        readOnly
                                                        require
                                                        value={state?.item?.description}
                                                        placeholder={'Please write a comment.....'} />

                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <CustomInput
                                                        label={'Reporter'}
                                                        require
                                                        placeholder={'Sushit M.'}
                                                        value={state?.item?.reporter?.name}
                                                        readOnly />
                                                </div>
                                                <div className="col-md-3">
                                                    <CustomInput
                                                        label={'Issue type'}
                                                        require
                                                        value={state?.item?.issue_type?.name}
                                                        readOnly />
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <CustomInput
                                                        label={'Plant'}
                                                        require
                                                        value={state?.item?.company_plants?.plant_name}
                                                        readOnly />
                                                </div>

                                                <div className="col-md-3">
                                                    <CustomInput
                                                        label={'Entity'}
                                                        require
                                                        placeholder={'Sushit M.'}
                                                        value={state?.item?.entity?.name}
                                                        readOnly />
                                                </div>

                                                <div className="col-md-3">
                                                    <CustomInput
                                                        label={'Select Category'}
                                                        placeholder={'Select Category'}
                                                        value={state?.item?.category?.category_name}
                                                        require
                                                        readOnly />
                                                </div>
                                                <div className="col-md-3">
                                                    <CustomInput
                                                        label={'Select Sub-Category'}
                                                        placeholder={'Select Sub-Category'}
                                                        require
                                                        value={state?.item?.subcategory?.subcategory_name}
                                                        readOnly />
                                                </div>
                                                <div className="col-md-3">
                                                    <CustomInput
                                                        label={'Select Status'}
                                                        placeholder={'Select Status'}
                                                        value={state?.item?.status?.complaint_status_name}
                                                        require
                                                        readOnly />
                                                </div>
                                                <div className="col-md-3">
                                                    <CustomInput
                                                        label={'Select Priority'}
                                                        placeholder={'Select Priority'}
                                                        value={state?.item?.priority?.priority_name}
                                                        require
                                                        readOnly />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            />

                            <CustomCard
                                cardboldtitle={"TR Number"}
                                cardbody={
                                    <div className="ticket_detaile_box">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="tr_table_box">
                                                    <thead className="trNumber">
                                                        <tr>
                                                            <th>S.no.</th>
                                                            <th>Number</th>
                                                            <th>Description</th>
                                                        </tr>
                                                    </thead>
                                                    {state?.item?.tr_numbers?.length > 0 ?
                                                        (
                                                            state?.item?.tr_numbers?.map((item, i) => {
                                                                return (
                                                                    <tbody className="trNumber">
                                                                        <tr key={item?.tr_number}>
                                                                            <td>{i + 1}</td>
                                                                            <td>{item?.tr_number}</td>
                                                                            <td>{item?.description}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                )
                                                            })
                                                        )
                                                        : (
                                                            <tbody>
                                                                <tr>
                                                                    <td className="text-center p-3" colSpan={3}>
                                                                        No Records found...
                                                                    </td>
                                                                </tr>
                                                            </tbody>

                                                        )}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                }
                            />

                            <CustomCard
                                cardtitle={"Ticket"}
                                cardboldtitle={"Details"}

                                cardbody={
                                    <div className="ticket_detaile_box">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <img className="commentImg1" src={commentImg1} alt="comment image" />
                                                <img className="commentImg" src={commentImg} alt="comment image" />
                                                <Tabs
                                                    defaultActiveKey="profile"
                                                    id="uncontrolled-tab-example"
                                                    className="mb-3"
                                                >

                                                    <Tab eventKey="profile" title="Internal Comment">
                                                        <CustomInput placeholder={'Comment'} />
                                                        <h3 className="comment_title">Comments History</h3>
                                                        <div className="comment_main_box">
                                                            {state?.item?.comments?.filter((item) => item.is_internal).map((item, index) => (
                                                                <div className="comment_box" key={index}>
                                                                    <h3 className="comment_title">
                                                                        {item.senderName}
                                                                        <span className="comment_time">
                                                                            {getFormattedDate(item.created_at)}
                                                                        </span>
                                                                    </h3>
                                                                    <p className="comment_text">{item.content}</p>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </Tab>
                                                    <Tab eventKey="home" title="Reply to End User">
                                                        <CustomInput placeholder={'Comment'} />
                                                        <h3 className="comment_title">Comments History</h3>
                                                        <div className="comment_main_box">
                                                            {state?.item?.comments?.filter((item) => !item.is_internal).map((item, index) => (
                                                                <div className="comment_box" key={index}>
                                                                    <h3 className="comment_title">
                                                                        {item.senderName}
                                                                        <span className="comment_time">
                                                                            {getFormattedDate(item.created_at)}
                                                                        </span>
                                                                    </h3>
                                                                    <p className="comment_text">{item.content}</p>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </Tab>
                                                </Tabs>
                                            </div>
                                        </div>
                                    </div>
                                }
                            />


                            <CustomCard
                                cardtitle={"Audit"}
                                cardboldtitle={" trail"}
                                toprightview={
                                    <>
                                        <CustomButton style={"me-3"} children={'Show All Pipeline'} onClick={toggleVisibility} />
                                        <button className="export-btn" onClick={exportToExcel}>
                                            <div>
                                                <img
                                                    width={20}
                                                    className="me-2"
                                                    src={excelIcon}
                                                    alt="Export to Excel"
                                                />
                                                Export
                                            </div>
                                        </button>
                                    </>
                                }
                                cardbody={
                                    <React.Fragment>
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead class="table-customlight">
                                                    <tr>
                                                        <th className="auditTrailcms">Status Date & Time</th>
                                                        <th className="auditTrailcms">Name</th>
                                                        <th className="auditTrailcms">Support Level</th>
                                                        <th className="auditTrailcms">Status</th>
                                                        <th className="auditTrailcms">Comment</th>
                                                    </tr>
                                                </thead>
                                                {dataToDisplay?.length > 0 && (
                                                    <tbody>
                                                        {dataToDisplay?.map((item, i) => {
                                                            return (
                                                                <tr key={item?.requestNumber}>
                                                                    <td scope="row"><FormatDate dateString={item?.created_at} /> </td>
                                                                    <td>{item?.reporter_name}</td>
                                                                    <td>{item?.support_level}</td>
                                                                    <td>{item?.status}</td>
                                                                    <td className="status-box">{item?.comments}</td>
                                                                </tr>)
                                                        })}
                                                    </tbody>)}
                                            </table>
                                        </div>
                                    </React.Fragment>
                                }
                            />

                            <div className='col-md-12 text-end'>
                                {/* <CustomButton style={"btn--outline me-3"} children={"Back"} onClick={() => navigate("/ticket")} /> */}
                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </div >
    );
};

export default ViewTickets;
