import React, { useState, useEffect } from "react";
import CustomCard from "./CustomCard";
import CustomButton from "./Button/Button";
import "./style.scss";
import { useNavigate, useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Customselect from "./CustomSelect";
import { Link } from "react-router-dom";
import * as XLSX from 'xlsx';
import excelIcon from '../assets/images/excel.png';





const ComplaintDashboard = ({ handleView, allComplainData, complaintData, handlePageSubmit, page, pageLimit, limit, handleEdit,
    limitChangeHandler, handleFilter, handleReset, categoryList, handleSelectCategory, selectCategory, priorityList, handlePriority, selectedPriority, targetRowRef }) => {

    const location = useLocation();
    const url = location?.pathname?.split("/")[1];
    const navigate = useNavigate()
    const [selectedCandidateId, setSelectedCandidateId] = useState(sessionStorage.getItem('selectedCandidateId') || null);


    const getClassName = (status) => {

        switch (status) {
            case 'Open':
                return 'circle-yellow';
            case 'Close':
                return 'circle-red';
            case 'UAT Pending':
                return 'circle-yellow';
            case 'In-Progress':
                return 'circle-yellow';
            case 'Cancelled':
                return 'circle-red';
            case 'UAT Completed':
                return 'circle-green';
            default:
                return '';
        }
    };

    useEffect(() => {
        if (selectedCandidateId) {
            sessionStorage.setItem('selectedCandidateId', selectedCandidateId);
        }
    }, [selectedCandidateId]);

    // const handleEditClick = (candidate) => {
    //     setSelectedCandidateId(candidate.complaint_id);
    //     handleEditForm(candidate);
    //     sessionStorage.setItem('selectedCandidateId', candidate.complaint_id);
    // };

    const handleViewClick = (candidate) => {
        setSelectedCandidateId(candidate.complaint_id);
        handleViewForm(candidate);
        sessionStorage.setItem('selectedCandidateId', candidate.complaint_id);
    };

    const getFormattedDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
    };


    const handleViewForm = (e) => handleView(e);
    // const handleEditForm = (e) => handleEdit(e);

    useEffect(() => {
        const storedPage = sessionStorage.getItem('currentPage');
        const storedPageLimit = sessionStorage.getItem('pageLimit');

        if (storedPage) {
            handlePageSubmit({ selected: parseInt(storedPage) - 1 });
        }

        if (storedPageLimit) {
            limitChangeHandler(storedPageLimit);
        }
    }, []);

    const scrollToRow = () => {
        const element = document.getElementById(selectedCandidateId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            element.focus();
        }
    };

    useEffect(() => {
        setTimeout(() => {
            scrollToRow();
        }, 1000);

    }, [selectedCandidateId]);

    if (complaintData.length > 0) {
        scrollToRow()
    }

    const [currentPage, setCurrentPage] = useState(() => {
        return parseInt(sessionStorage.getItem('currentPage'), 10) - 1 || 0;
    });

    useEffect(() => {
        sessionStorage.setItem('currentPage', currentPage + 1);
    }, [currentPage]);

    const handlePageChange = (selectedItem) => {
        const selectedPage = selectedItem.selected;
        setCurrentPage(selectedPage);
        handlePageSubmit({ selected: selectedPage });
    };

    const handleResett = () => {
        setCurrentPage(0)
        handleReset()
    }


    const exportToExcel = () => {
        const dataToExport = complaintData.map((item, i) => ({
            index: i + 1,
            complaint_id: item.complaint_id,
            date: item.created_at && getFormattedDate(item.created_at),
            business: item.business?.business_name,
            company: item.company?.name,
            category: item.category?.category_name,
            status: item.status?.complaint_status_name,
            priority: item.priority?.priority_name,
            assignee: item.assignee?.name,
            issue_summary: item.issue_summary,
        }));

        const worksheet = XLSX.utils.json_to_sheet(dataToExport);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'ComplaintData.xlsx');
    };




    return (
        <div className="manpower-requisition-container">
            <CustomCard
                cardtitle={"All "}
                cardboldtitle={"Tickets"}
                toprightview={
                    <div>
                        {window?.location?.pathname !== "/view-all-ticket" && <CustomButton style={"me-3"} children={"View All Tickets"} onClick={() => navigate("/view-all-ticket")} />}
                        <button className="export-btn" onClick={exportToExcel}>
                            <div>
                                <img
                                    width={20}
                                    className="me-2"
                                    src={excelIcon}
                                    alt="Export to Excel"
                                />
                                Export
                            </div>
                        </button>
                    </div>
                }
                cardbody={
                    <React.Fragment>
                        <div className="row">
                            {window.location.pathname !== "/ticket" && <div className=" filter-container">
                                <div className="col-md-3">
                                    <Customselect
                                        label="Select Category"
                                        value={selectCategory}
                                        onChange={(e) => {
                                            handleSelectCategory(e.target.value);
                                        }}
                                        options={categoryList}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <Customselect
                                        label="Select Priority"
                                        value={selectedPriority}
                                        onChange={(e) => {
                                            handlePriority(e.target.value);
                                        }}
                                        options={priorityList}
                                    />
                                </div>


                                <div className=" col-md -3 btn-container" style={{ margin: "auto" }}>
                                    <CustomButton style={"me-3"} children={'Search'} onClick={handleFilter} />
                                    <CustomButton style={"me-3"} children={'Reset'} onClick={handleResett} />
                                </div>

                            </div>}


                            {window.location.pathname !== "/ticket" && <div className="col-md-12 mb-4">
                                <div>
                                    <label className="d-flex align-items-center p-0 col-md-2">
                                        {page === 1 ? (
                                            <select
                                                value={pageLimit}
                                                className="form-control selectbox-container mr-2 drop-down-dashboard"
                                                onChange={(e) => limitChangeHandler(e.target.value)}
                                            >
                                                {limit?.length > 0
                                                    ? limit?.map((item, index) => (
                                                        <option key={index} value={item.option}>
                                                            {item.value}
                                                        </option>
                                                    ))
                                                    : null}
                                            </select>
                                        ) : (
                                            <p className="form-control selectbox-container mr-2 drop-down-dashboard">
                                                {pageLimit}
                                            </p>
                                        )}
                                        <span> entries</span>
                                    </label>
                                </div>
                            </div>}


                            <div class="table-responsive">
                                <table class="table">
                                    <thead class="table-customlight">
                                        <tr>
                                            <th scope="col" className="manpower-table1">Candidate ID.</th>
                                            <th scope="col" className="manpower-table">Creation Date</th>
                                            <th scope="col" className="manpower-table2">Summary</th>
                                            <th scope="col" className="manpower-table">Category</th>
                                            {url !== "hr-portal-approver" && <th scope="col" className="manpower-table">Status</th>}
                                            <th scope="col" className="manpower-table">Priority</th>
                                            <th scope="col" className="manpower-table">Assignee</th>
                                            <th scope="col" className="manpower-table1">Action</th>
                                        </tr>
                                    </thead>
                                    {complaintData?.length > 0 ? (
                                        <tbody>
                                            {complaintData?.map(candidate => {
                                                const isSelected = candidate.complaint_id == selectedCandidateId;

                                                return (
                                                    <tr ref={targetRowRef}
                                                        key={candidate.complaint_id}
                                                        className={isSelected ? 'highlighted-row' : ''}
                                                        id={candidate.complaint_id}>
                                                        <td scope="row">{candidate?.complaint_id}</td>
                                                        <td>{candidate?.created_at?.split("T")[0]}</td>
                                                        <td>{candidate?.issue_summary}</td>
                                                        <td>{candidate?.category?.category_name}</td>
                                                        <td>
                                                            <div className="circle-box">
                                                                <span className={getClassName(candidate?.status?.complaint_status_name)}></span> {candidate?.status?.complaint_status_name}
                                                            </div>
                                                        </td>
                                                        <td>{candidate?.priority?.priority_name}</td>
                                                        <td>{candidate?.assignee?.name}</td>
                                                        <td>
                                                            {/* <img src={require("../assets/images/editCrm.png")} className="requisition-edit me-1" onClick={() => handleEditForm(candidate)} /> */}
                                                            <img src={require("../assets/images/visible.png")} 
                                                            className="requisition-edit me-3" 
                                                            onClick={(e) => {
                                                                e.stopPropagation(); // Prevent row click
                                                                handleViewClick(candidate);
                                                            }}
                                                            />
                                                        </td>

                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    ) : (
                                        <tbody>
                                            <tr>
                                                <td className="text-center p-3" colSpan={8}>
                                                    No Records found...
                                                </td>
                                            </tr>
                                        </tbody>

                                    )}
                                </table>
                            </div>
                            {window.location.pathname !== "/ticket" && <div className="col-md-12">
                                <div className="d-flex align-items-center justify-content-between my-4 admin-pagination">
                                    <div>
                                        <p>
                                            showing {allComplainData?.filteredCount} out of{" "}
                                            {allComplainData?.totalRecords}
                                        </p>
                                    </div>
                                    <div>
                                        <ReactPaginate
                                            previousLabel={"Previous"}
                                            nextLabel={"Next"}
                                            breakLabel={"..."}
                                            pageCount={allComplainData?.totalPages}
                                            marginPagesDisplayed={3}
                                            pageRangeDisplayed={3}
                                            onPageChange={handlePageSubmit}
                                            containerClassName={"pagination paginationBox justify-content-end"}
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link pageNumber"}
                                            previousClassName={"page-item previosBtn"}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link previosBtn"}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link pageNumber"}
                                            activeClassName={"active"}
                                            forcePage={currentPage}
                                        />
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </React.Fragment>
                }
            />
        </div>
    );
};

export default ComplaintDashboard;
