import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./../modalStyle.scss";
import CustomInput from "../../CustomInput";
import CustomButton from "../../Button/Button";
import Customselect from "../../CustomSelect";
import { useStores } from "../../../stores";
import notify from "../../../shared/notify";

const AddBankModal = (props) => {
  const { entitiesList } = props;
  const { mainStore } = useStores();
  const [banks, setBanks] = useState([
    { bankName: "", selectedEntity: "", bankAccountNumber: "", glCode: "" },
  ]);
  const resetBanks = () => {
    setBanks([{ bankName: "", selectedEntity: "", bankAccountNumber: "", glCode: "" }]);
  };


  const addBankDetails = async () => {
    const { selectedEntity } = banks[0];

    if (!selectedEntity) {
      return notify("Please Enter Entity code", "danger");
    }

    for (const bank of banks) {
      const { bankName, bankAccountNumber, glCode } = bank;

      if (!bankName) {
        return notify("Please Enter Name", "danger");
      }
      if (!bankAccountNumber) {
        return notify("Please Enter Bank Account Number", "danger");
      }
      if (!glCode) {
        return notify("Please Enter GL Code", "danger");
      }
    }

    let payload = {
      bankData: banks?.map(({ bankName, bankAccountNumber, glCode }) => ({
        bank_name: bankName,
        entity_code: banks[0]?.selectedEntity,
        bank_account_number: bankAccountNumber,
        glcode: glCode,
      })),
    };

    await mainStore.addBank(payload, (response) => {
      if (response?.data?.meta?.status === "success") {
        notify(response?.data?.meta?.message);
        props.onHideModal();
        resetBanks()
      } else {
        notify(response?.data?.meta?.message, "danger");
        resetBanks()
      }
    },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        resetBanks()
      }
    );
  };

  const addNewBankFields = () => {
    const firstBankEntity = banks[0]?.selectedEntity || ""
    setBanks([
      ...banks,
      { bankName: "", selectedEntity: firstBankEntity, bankAccountNumber: "", glCode: "" },
    ]);
  };

  const updateBankField = (index, field, value) => {
    const updatedBanks = [...banks];
    updatedBanks[index][field] = value;
    setBanks(updatedBanks);
  };

  const deleteBankField = (index) => {
    debugger
    setBanks(banks.filter((_, i) => i !== index));
  };

  console.log(banks ,"banksssss");



  return (
    <Modal {...props} centered className="sucess-content bank-data" onHide={() => {
      resetBanks();
      props.onHideModal();
    }} >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add Bank Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {banks?.map((bank, index) => (
          <div key={index} className="row add-bank-detail">
            {index === 0 &&
              <div className="col-md-6">
                <Customselect
                  multiSelect={false}
                  label={"Entities"}
                  value={bank.selectedEntity}
                  options={entitiesList}
                  defaultValue={bank.selectedEntity}
                  onChange={(e) => updateBankField(index, "selectedEntity", e.target.value)}
                />
              </div>
            }
            <div className="col-md-6">
              <CustomInput
                label={"Bank Name"}
                value={bank.bankName}
                onChange={(e) => updateBankField(index, "bankName", e.target.value)}
                placeholder={"Enter Bank Name"}
              />
            </div>

            <div className="col-md-6">
              <CustomInput
                label={"Bank Account Number"}
                value={bank.bankAccountNumber}
                onChange={(e) => updateBankField(index, "bankAccountNumber", e.target.value)}
                placeholder={"Enter Bank Account Number"}
              />
            </div>

            <div className="col-md-6">
              <CustomInput
                label={"Gl Code"}
                value={bank.glCode}
                onChange={(e) => updateBankField(index, "glCode", e.target.value)}
                placeholder={"Enter Gl Code"}
              />
            </div>
            {index > 0 && (
              <div className="col-md-6 d-flex align-items-end">
                <div style={{ marginBottom: "16px" }}><CustomButton styles={{ width: 120, height: 38, fontSize: 16 }}onClick={() => deleteBankField(index)}children={"Delete"}/>
                </div>
              </div>
            )}
          </div>
        ))}
        <div className="d-flex justify-content-evenly">
          <div className="text-center mt-3"> <CustomButton styles={{ width: 120, height: 38, fontSize: 16 }} onClick={addBankDetails} children={"Submit"} /></div>
          <div className="text-center mt-3"><CustomButton styles={{ width: 120, height: 38, fontSize: 16 }} onClick={addNewBankFields} children={"Add Bank"} /></div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddBankModal;

