import axios from "axios";

const baseUrl = process.env.REACT_APP_API_BASE;
const adminUrl = process.env.REACT_APP_ADMIN_API_BASE
class Service {
    constructor(props) {
        this.refreshHeader(() => { });
    }

    refreshHeader(callback) {
        const sessionToken = sessionStorage.getItem("token");
        // const sessionToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NCwiaWF0IjoxNzEyNjU4MzA0LCJleHAiOjE3MTI3NDQ3MDR9.3lgswuedy1wcptUcCq_2L_ZLLewPX2_y-tIJq9UnLNQ";
        let srh = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": sessionToken
            },
        };

        let service = axios.create(srh);
        service.interceptors.response.use(this.handleSuccess, this.handleError);
        this.service = service;
        return callback();
    }

    handleSuccess(response) {
        return response;
    }

    handleError = (error) => {
        let code = 400;
        let message = "";
        if (typeof error.response != "undefined") {
            if (typeof error.response?.data?.meta?.code != "undefined") {
                code = error.response.data.meta.code;
                message = error.response.data.meta.message;
            }
            if (error.response.statusText === "Unauthorized") {
                code = error.response.status;
                message = error.response.statusText;
            }
        }

        switch (code) {
            case 401:
                sessionStorage.removeItem("token");
                window.location.href = `${process.env.PUBLIC_URL}/`;
                break;
            case 404:
                break;
            default:
                if (message !== "") {
                }
                break;
        }
        return Promise.reject(error);
    };

    redirectTo = (document, path) => {
        document.location = path;
    };


    async get(path, callback) {
        let response = await this.service.request(`${adminUrl}/${path}`);
        callback(response.status, response.data);
        return response;
    }
    async getUser(path, callback) {
        let response = await this.service.request(`${baseUrl}/${path}`);
        callback(response.status, response.data);
        return response;
    }


    async patch(path, payload, callback) {
        let response = await this.service.request({
            method: "PATCH",
            url: `${adminUrl}/${path}`,
            responseType: "json",
            data: payload,
        });
        return callback(response.status, response.data);

    }

    async post(path, payload, callback) {
        let response = await this.service.request({
            method: 'POST',
            url: `${adminUrl}/${path}`,
            responseType: 'json',
            data: payload
        })
        return callback(response);
    }

    async postFile(path, payload, callback) {
        let response = await this.service.request({
            method: 'POST',
            url: `${adminUrl}/${path}`,
            responseType: 'json',
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: payload
        })
        return callback(response);
    }

    async put(path, payload, callback) {
        let response = await this.service.request({
            method: "PUT",
            url: `${adminUrl}/${path}`,
            responseType: "json",
            data: payload,
        });
        callback(response.status, response.data);
        return response;
    }

    async delete(path, payload, callback) {
        let response = await this.service.request({
            method: 'DELETE',
            url: `${adminUrl}/${path}`,
            responseType: "json",
            data: payload
        });
        return callback(response);
    }
}

export default new Service();
