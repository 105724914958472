
import React, { useEffect, useRef } from 'react';
import './style.scss';
import 'quill/dist/quill.snow.css';
import { useQuill } from 'react-quilljs';

function TextEditor(props) {
  const { readOnly, onChange, value, label, require, placeholder, style } = props;
  const { quill, quillRef } = useQuill({
    theme: 'snow',
    readOnly,
    placeholder,
    modules: {
      toolbar: readOnly
        ? false
        : {
            container: [
              [{ header: '1' }, { header: '2' }],
              ['bold', 'italic', 'underline'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              ['link', 'image'],
            ],
          },
    },
  });

  const initialValueSet = useRef(false);
  useEffect(() => {
    if (quill && !initialValueSet.current) {
      try {
        if (typeof value === 'string' && value.trim() !== '') {
          quill.clipboard.dangerouslyPasteHTML(value);
        } else {
          quill.setText('');
        }

        quill.enable(!readOnly);
        initialValueSet.current = true;
        if (!readOnly) {
          quill.getModule('toolbar')?.addHandler('image', handleImageUpload);
          quill.root.addEventListener('paste', handlePasteImage);
        }
      } catch (error) {
        console.error('Error setting editor content:', error);
      }
    }

    return () => {
      if (quill) {
        quill.root?.removeEventListener('paste', handlePasteImage);
      }
    };
  }, [quill, value, readOnly]);

  useEffect(() => {
    if (quill && !readOnly) {
      const handleTextChange = () => {
        if (onChange) {
          const htmlContent = quill.root.innerHTML; 
          onChange(htmlContent);
        }
      };

      quill.on('text-change', handleTextChange);

      return () => {
        quill.off('text-change', handleTextChange);
      };
    }
  }, [quill, onChange, readOnly]);

  const handleImageUpload = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const base64ImageSrc = e.target.result;
          console.log('Base64 image uploaded:', base64ImageSrc);

          const range = quill.getSelection();
          if (range) {
            quill.insertEmbed(range.index, 'image', base64ImageSrc);
          } else {
            console.error('Quill selection range not found.');
          }
        };

        reader.onerror = (error) => {
          console.error('Error reading file:', error);
        };

        reader.readAsDataURL(file);
      } else {
        console.warn('No file selected.');
      }
    };

    input.onerror = (error) => {
      console.error('Error with file input:', error);
    };
  };

  const handlePasteImage = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const items = clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.type.indexOf('image') !== -1) {
        const file = item.getAsFile();
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64ImageSrc = e.target.result;
          console.log('Base64 image pasted:', base64ImageSrc);
          const range = quill.getSelection();
          if (range) {
            quill.insertEmbed(range.index, 'image', base64ImageSrc);
          }
        };
        reader.readAsDataURL(file);
        event.preventDefault();
      }
    }
  };

  return (
    <div className="text_editor_main_section" style={style}>
      {label && (
        <label className="input_label">
          {label}
          {require && <span className="text-danger">*</span>}
        </label>
      )}
      <p className="Description_text">
        (You can attach the screenshot by Ctrl+C + Ctrl+V feature and attachments should not exceed 10MB each)
      </p>

      <div className="editor-container textarea">
        <div ref={quillRef} style={style} />
      </div>
    </div>
  );
}

export default TextEditor;
