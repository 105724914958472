import React from "react";
import "./customselect.scss";
import Select from "react-select";

const Customselect = (props) => {
  const {
    label,
    blankLabel,
    value,
    onChange,
    style,
    optionview,
    options,
    defaultValue,
    multiSelect
  } = props;

  return (
    <div className="customselect-container">
      <div className="custom-select">
        <label>
          {label}
          {props.require && <span className="text-danger">*</span>}
        </label>
        {multiSelect ? (
          <Select
            isMulti
            options={options}
            defaultValue={defaultValue}
            onChange={onChange}
          />
        ) : (
          <select className={style} value={value} onChange={onChange}>
            {!blankLabel && <option value="">Please Select</option>}
            {options?.length > 0 && options?.map((item)=>{
              return(
                <option value={item.value}>{item.label}</option>
              )
            })}
          </select>
        )}
      </div>
    </div>
  );
};

export default Customselect;
