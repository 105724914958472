import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./../modalStyle.scss";
import CustomInput from "../../CustomInput";
import CustomButton from "../../Button/Button";
import { useStores } from "../../../stores";
import notify from "../../../shared/notify";

const AddEntityModal = (props) => {
  const { mainStore } = useStores();
  const [name, setName] = useState("");
  const [entityCode, setEntityCode] = useState("");

  const addEntityFun = async () => {
    if (name === "undefined" || name === "" || name === "null") {
      return notify("Please Enter Name", "danger");
    }
    if (entityCode === "undefined" || entityCode === "" || entityCode === "null") {
      return notify("Please Enter Entity code", "danger");
    }
    let payload = {
      name: name,
      entityCode: entityCode
    };
    await mainStore.addEntities(
      payload,
      (response) => {
        if (response?.data?.meta?.status === "success") {
          notify(response?.data?.meta?.message);
          props.onHideModal();
        } else {
          notify(response?.data?.meta?.message, "danger");
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
      }
    );
  };

  return (
    <Modal {...props} centered className="sucess-content">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add Entity</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CustomInput
          label={"Name"}
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          placeholder={"Enter Name"}
        />
        <CustomInput
          label={"Entity Code"}
          value={entityCode}
          onChange={(e) => {
            setEntityCode(e.target.value);
          }}
          placeholder={"Enter Entity Code"}
        />

        <div className="text-center mt-3">
          <CustomButton
            styles={{ width: 120, height: 38, fontSize: 16 }}
            onClick={() => {
              addEntityFun();
            }}
            children={"Submit"}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddEntityModal;
