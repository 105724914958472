import React, { useState, useEffect } from 'react'
import Header from "../../components/Header";
import { useStores } from "../../stores";
import notify from "../../shared/notify";
import CustomCard from "../../components/CustomCard";
import CustomButton from "../../components/Button/Button";
import ResponsivePagination from "react-responsive-pagination";
import CustomInput from '../../components/CustomInput';
import Loader from '../../Helper/Loader';
import AddSubCategory from '../../components/Modal/SubCategory/addSubCategory';
import EditSubCategory from '../../components/Modal/SubCategory/editSubCategory';

const SubCategory = () => {
    const { mainStore } = useStores()
    const [name, setName] = useState("")
    const [CategoryList, setCategoryList] = useState([])
    const [totalPage, setTotalPage] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    let loginUserData = sessionStorage.getItem("userData");
    let parseuserData = JSON.parse(loginUserData);
    const [addModal, setAddModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [category, setCategory] = useState("")
    const [editcategoryName, setEditCategoryName] = useState("")
    const [showEditModal, setShowEditModal] = useState(false)
    const [item, setItem] = useState()
    const [status, setStatus] = useState();
    const [catDropDownData , setCatDropDownData] = useState([])
    const[addSubCategory , setAddSubCategory] = useState("")
    const[editSubCategoryName , setEditSubCategoryName] = useState("")



    useEffect(() => {
        fetchSubCategory()
    }, [name])

    let options = [
        { value: "true", label: "Active" },
        { value: "false", label: "Inactive" },
    ];



    const fetchSubCategory = async (page) => {
        let payload = {
            pageNumber: page ? page : 1,
            limit: 10,
            filters: {},
        };
        if (name) {
            payload.filters.subcategory_name = name;
        }
        setIsLoading(true)
        await mainStore.getSubcategory(
            payload,
            (response) => {
                if (response?.data?.meta?.status === "success") {
                    setCategoryList(response?.data?.data?.filteredData?.results)
                    setTotalPage(response?.data?.data?.totalPages);
                    setIsLoading(false)
                } else {
                    setCategoryList([]);
                    setTotalPage(null);
                    notify(response?.data?.meta?.message, "danger");
                    setIsLoading(false)
                }
            },
            (error) => {
                setCategoryList([]);
                setTotalPage(null);
                notify(error?.response?.data?.meta?.message, "danger");
                setIsLoading(false)
            }
        );
    };

    const handleEdit = (item) => {
        console.log(item,"editttttt");
        setEditCategoryName(item?.category_id)
        setEditSubCategoryName(item?.subcategory_name)
        setStatus(item?.is_active)
        setShowEditModal(true)
        setItem(item)
    }

   

    const handlePageChange = (pageNumber) => {
        fetchSubCategory(pageNumber);
        setCurrentPage(pageNumber);
    }

 


    const Addcategory = async () => {
        let payload = {
            category_id: category,
            subcategory_name:addSubCategory
        }
        debugger
        setIsLoading(true)
        await mainStore.addSubCategory(payload, (res) => {
            if (res?.data?.meta?.status === "success") {
                notify(res?.data?.meta?.message)
                setIsLoading(false)
                setAddModal(false)
                setCategory("")
                setAddSubCategory("")
                fetchSubCategory()
            } else {
                notify(res?.data?.meta?.message, "danger")
                setIsLoading(false)
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
            setIsLoading(false)
        })
    }

    const editSubCategory = async () => {
        debugger
        let payload = {
            category_id: editcategoryName,
            subcategory_name: editSubCategoryName,
            is_active: status
        }
        setIsLoading(true)
        await mainStore.editSubCategory(item?.subcategory_id ,payload, (res) => {
            if (res?.data?.meta?.status === "success") {
                notify(res?.data?.meta?.message)
                fetchSubCategory()
                setIsLoading(false)
                setShowEditModal(false)
            } else {
                notify(res?.data?.meta?.message, "danger")
                setIsLoading(false)
            }
        },(error) =>{
            notify(error?.response?.data?.meta?.message, "danger")
            setIsLoading(false)
        })

    }

    useEffect(() =>{
        fetchcategory()
    },[])

    const fetchcategory = async () => {
        await mainStore.getAllCategoryWithoutFilter((status, response) => {
          if (response?.meta?.status === "success") {
            let data = response?.data?.categories;
            let category = [];
            for (let i = 0; i < data?.length; i++) {
              category.push({ label: data[i].category_name, value: data[i].category_id });
            }
            setCatDropDownData(category)
          } else {
            notify(response?.meta?.message, "danger")
          }
        }, (error) => {
          notify(error?.response?.data?.meta?.message, "danger")
        })
      }


    return (
        <React.Fragment>
            {isLoading && <Loader />}
            <div className="dashboard-container">
                <div className="right-view">
                    <Header title={`Welcome ${parseuserData?.name},`} />
                    <div className="mt-3">
                        <CustomCard
                            cardtitle={"Sub "}
                            cardboldtitle={" Category List"}
                            searchName={
                                <CustomInput
                                    label={"Search Sub Category Name"}
                                    placeholder={"Search Sub Category Name"}
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value);
                                    }}
                                />
                            }
                            toprightview={
                                <CustomButton
                                    onClick={() => {
                                        setAddModal(true)
                                    }}
                                    children={"Add Sub Category"}
                                />
                            }
                            cardbody={
                                <div className="container-fluid px-0">
                                    <div class="table-responsive listing-table">
                                        <table class="table ">
                                            <thead class="table-customlight">
                                                <tr>
                                                    <th scope="col">S.NO</th>
                                                    <th scope="col">Category</th>
                                                    <th scope="col">Sub Category</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {CategoryList?.length > 0 &&
                                                    CategoryList.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <td>{index + 1}</td>
                                                                <td>{item?.category_name}</td>
                                                                <td>{item?.subcategory_name}</td>
                                                                <td>
                                                                    {item?.is_active ? "Active" : "Inactive"}
                                                                </td>
                                                                <td>
                                                                    <img
                                                                        onClick={() => handleEdit(item)}
                                                                        width={20}
                                                                        style={{ cursor: "pointer" }}
                                                                        src={require("../../assets/images/edit.png")}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </table>
                                        {CategoryList?.length == 0 && (
                                            <p className="page-not-found" style={{ height: 200 }}>
                                                No Records Found
                                            </p>
                                        )}
                                    </div>
                                    {CategoryList?.length > 0 && (
                                        <div className="pagination-container">
                                            <ResponsivePagination
                                                current={currentPage}
                                                total={totalPage}
                                                onPageChange={(e) => {
                                                    handlePageChange(e);
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            }
                        />
                    </div>
                    <AddSubCategory
                        show={addModal}
                        onHide={() =>
                            setAddModal(false)
                        }
                        onHideModal={() => {
                            setAddModal(false);
                            fetchSubCategory();
                        }}
                        category={category}
                        handlecategoryName={(e)=>setCategory(e)}
                        Addcategory={Addcategory}
                        catDropDownData={catDropDownData}
                        handleAddSubCat={(e) => setAddSubCategory(e)}
                        addSubCategory={addSubCategory}
                    />
                    <EditSubCategory
                        show={showEditModal}
                        onHide={() =>
                            setShowEditModal(false)
                        }
                        onHideModal={() => {
                            setShowEditModal(false);
                            fetchSubCategory();
                        }}
                        editcategoryName={editcategoryName}
                        handleEditcategoryName={(e)=> setEditCategoryName(e)}
                        handleEditSubCat={(e) => setEditSubCategoryName(e)}
                        editSubCategoryName={editSubCategoryName}
                        editSubCategory={editSubCategory}
                        options={options}
                        status={status}
                        handleChangeStatus={(e) =>setStatus(e)}
                        catDropDownData={catDropDownData}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default SubCategory