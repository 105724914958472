import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./../modalStyle.scss";
import CustomInput from "../../CustomInput";
import CustomButton from "../../Button/Button";
import notify from "../../../shared/notify";

const AddBusiness = ({show , addBusiness , onHideModal , business ,handleBusinessName }) => {
    
  const handleSubmit =() =>{
    addBusiness()
  }

  return (
    <Modal show={show} centered className="sucess-content" onHide={() => onHideModal()} >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add Business</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CustomInput
          label={"Business"}
          value={business}
          onChange={(e) => {handleBusinessName(e.target.value)}}
          placeholder={"Enter Business"}
        />

        <div className="text-center mt-3">
          <CustomButton
            styles={{ width: 120, height: 38, fontSize: 16 }}
            onClick={() => handleSubmit()}
            children={"Submit"}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddBusiness;
