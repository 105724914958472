import React from "react";
import { Chart } from "react-google-charts";



const GraphChart = (props) => {
  const allZero = props.data.slice(1).every((item) => item[1] === 0);

  if (allZero) {
    return <div>No data available to display the chart</div>;
  }

    const chartOptions = {
    ...props.options,
    fontName: "Roboto", 
    fontSize: 14, 
  }
  return (
    <Chart
      chartType="PieChart"
      data={props.data}
      options={chartOptions}
      width={"100%"}
      height={"320px"}
      
      
    />
  );
}

export default GraphChart;
