import React, { useState } from "react";
import "./headerStyle.scss";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";

const Header = (props) => {
  const navigate = useNavigate();
  const handleLogout = () => {
    debugger
    sessionStorage.clear();
    // window.location.href = `/${process.env.REACT_APP_BASE_FOLDER}/`
    clearCacheData()
  }


  const clearCacheData = () => {
    if ("caches" in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
    navigate(`/`);
    window.location.reload();
  };

  return (
    <header className="HeaderSection">
      <div className="header-view">
        <div>
          <h2 className="page-title">{props.title}</h2>
          <div className="heading-underline"></div>
        </div>
        <div>
          <Dropdown>
            <Dropdown.Toggle variant="lightgray">
              <img
                class="user-profile-icon"
                src={require("../../assets/images/user.png")}
              />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                href={"javascript:void(0)"}
                onClick={() => {
                  handleLogout();
                }}
              >
                <img
                  width={18}
                  src={require("../../assets/images/logout.png")}
                />{" "}
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </header>
  );
};
export default Header;
