import axios from 'axios';
import { makeAutoObservable } from "mobx";
import Service from "../shared/service/service";



class authStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

}


export default authStore;
