import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./../modalStyle.scss";
import CustomInput from "../../CustomInput";
import CustomButton from "../../Button/Button";
import notify from "../../../shared/notify";
import Position from "../../../pages/position";

const AddPosition = ({show , AddPosition , onHideModal , position ,handleDesignationName }) => {
    
  const handleSubmit =() =>{
    AddPosition()
  }

  return (
    <Modal show={show} centered className="sucess-content" onHide={() => onHideModal()} >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add Position</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CustomInput
          label={"Position"}
          value={position}
          onChange={(e) => {handleDesignationName(e.target.value)}}
          placeholder={"Enter Position"}
        />

        <div className="text-center mt-3">
          <CustomButton
            styles={{ width: 120, height: 38, fontSize: 16 }}
            onClick={() => handleSubmit()}
            children={"Submit"}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddPosition;
