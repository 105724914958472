import React from "react";
import { Chart } from "react-google-charts";



const AllTicketsChart = (props) => {
    const allZero = props.data.slice(1).every((item) => item[1] === 0);

    if (allZero) {
      return <div>No data available to display the chart</div>;
    }
  
    return (
        <Chart
            chartType="Bar"
            width="100%"
            height="320px"
            data={props?.data}
            options={props?.options}
        />
    );
}

export default AllTicketsChart;
