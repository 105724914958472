import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import CustomCard from "../../components/CustomCard";
import "./style.scss";
import GraphChart from "../../components/GraphChart";
import Customselect from "../../components/CustomSelect";
import ApproverRoleCard from "../../components/ApproverRoleCard";
import Loader from "../../Helper/Loader";
import AllTicketsChart from "../../components/GraphChart/allTicketGraph";
import { useNavigate } from "react-router-dom";
import { useStores } from "../../stores";
import notify from "../../shared/notify";
import ComplaintDashboard from "../../components/ComplaintDashboard";



const Ticket = () => {
    const [lcChartShowType, setLCChartShowType] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [complaintData, setComplaintData] = useState([])
    const [allComplainData, setAllComplainData] = useState([])
    const navigate = useNavigate();
    const { mainStore } = useStores()
    const [page, setPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(10)
    const [dashboardData, setDashboardData] = useState("")




    const options = [
        { label: "Percentage Data", value: "percentage" },
        { label: "Number Data", value: "value" },
    ];


    const ticketData = [

        ["Task", "each Instrument "],
        [`Open Tickets ${Number(dashboardData?.openComplaints)}`, Number(dashboardData?.openComplaints)],
        [`Closed Tickets ${Number(dashboardData?.closedComplaints)}`, Number(dashboardData?.closedComplaints)],
        [`WIP ${Number(dashboardData?.inProgressComplaints)}`, Number(dashboardData?.inProgressComplaints)],
        [`Cancelled ${Number(dashboardData?.cancelledComplaints)}`, Number(dashboardData?.cancelledComplaints)],
        [`UAT Completed ${Number(dashboardData?.uatCompletedComplaints)}`, Number(dashboardData?.uatCompletedComplaints)],
        [`UAT Pending ${Number(dashboardData?.uatPendingComplaints)}`, Number(dashboardData?.uatPendingComplaints)],
    ];

    const limit = [
        {
            option: 10,
            value: 10,
        },
        {
            option: 100,
            value: 100,
        },
        {
            option: 500,
            value: 500,
        },
        {
            option: 1000,
            value: 1000,
        },
    ];


    const ticketOptions = {
        slices: {
            0: { color: "#a3b18a" },
            1: { color: "#3a5a40" },
            2: { color: "#e76f51" },
            3: { color: "#e65b5b" },
            4: { color: "#008000" },
            5: { color: "#a8a83d" },

        },
        chartArea: { width: 400, height: 400 },
        pieSliceText: lcChartShowType,
        sliceVisibilityThreshold: 0,
    };

    useEffect(() => {
        FetchedComplain()
    }, [])

    useEffect(() => {
        fetDashboardData()
    }, [])

    const fetDashboardData = async () => {
        debugger
        await mainStore.getDashboardCount((status, res) => {
            if (res?.meta?.status === "success") {
                setDashboardData(res?.data)
            }
        }, (error) => {
            notify(error?.response?.meta?.message, "danger")
        })
    }

    const FetchedComplain = async () => {
        let payload = {
            limit: pageLimit,
            filters: {
            },
            pageNumber: page
        }
        CallFetchedComplain(payload)

    }

    const CallFetchedComplain = async (payload) => {
        setIsLoading(true)
        await mainStore.getAllCmsComplaints(payload, (res) => {
            if (res?.data?.meta?.status === "success") {
                setComplaintData(res?.data?.data?.filteredData?.results?.slice(0, 4))
                setAllComplainData(res?.data?.data)
                setIsLoading(false)
            }

        }, (err) => {
            notify(err?.response?.data?.meta?.message, "danger")
            setIsLoading(false)
        })
    }

    const handlePageSubmitt = async (index) => {
        setPage(index.selected + 1);
        let payload = {
            limit: pageLimit,
            filters: {},
            pageNumber: index.selected + 1,
        };
        CallFetchedComplain(payload);
    };

    const limitChangeHandlerr = async (index) => {
        setPageLimit(index);
        let payload = {
            limit: index,
            filters: {},
            pageNumber: page,
        };
        CallFetchedComplain(payload);
    };

    const handleFilterr = async () => {
        let payload = {
            limit: pageLimit,
            filters: {
            },
            pageNumber: page,
        };
        CallFetchedComplain(payload);
    };

    const handleResett = async () => {
        setPage(1);
        setPageLimit(10);
        await FetchedComplain();
    };

    const data = [
        ["", `Open Tickets ${ Number(dashboardData?.openComplaints)}`,
            ` Closed Tickets  ${Number(dashboardData?.closedComplaints)}`,
            ` WIP ${ Number(dashboardData?.inProgressComplaints)}`,
            ` Cancelled ${ Number(dashboardData?.cancelledComplaints)}`,
            ` UAT Completed  ${ Number(dashboardData?.uatCompletedComplaints)}`,
            ` UAT Pending ${ Number(dashboardData?.uatPendingComplaints)}`,
        ],

        ['Tickets', Number(dashboardData?.openComplaints), Number(dashboardData?.closedComplaints), Number(dashboardData?.inProgressComplaints)
            , Number(dashboardData?.cancelledComplaints), Number(dashboardData?.uatCompletedComplaints), Number(dashboardData?.uatPendingComplaints)
        ],
    ];

    const optionsTicket = {
        colors: ['#A3B18A', "#395A3F", '#E66F51', "#e65b5b", "#008000", "#a8a83d"],
    };


    return (
        <div className="main-container dashboard_container_crm">
            {isLoading && <Loader />}
            <div className="right-view">
                <Header title={"Dashboard,"} />
                <div className="container-fluid px-0">
                    <div className="row">
                        <div className="col-md-3">
                            <ApproverRoleCard
                                src={require("../../assets/images/list.png")}
                                role={"Total Tickets"}
                                requests={dashboardData?.totalComplaints}
                            />
                        </div>
                        <div className="col-md-3">
                            <ApproverRoleCard
                                src={require("../../assets/images/pinklist.png")}
                                role={"Open Tickets"}
                                requests={dashboardData?.openComplaints}
                            />
                        </div>
                        <div className="col-md-3">
                            <ApproverRoleCard
                                src={require("../../assets/images/ticket-icon1.png")}
                                role={"WIP"}
                                requests={dashboardData?.inProgressComplaints}
                            />
                        </div>
                        <div className="col-md-3">
                            <ApproverRoleCard
                                src={require("../../assets/images/ticket-icon.png")}
                                role={"Closed Tickets"}
                                requests={dashboardData?.closedComplaints}
                            />
                        </div>
                        <div className="col-md-6">
                            <CustomCard
                                cardboldtitle={"All Tickets"}
                                toprightview={<Customselect
                                    onChange={(e) => {
                                        setLCChartShowType(e.target.value);
                                    }}
                                    blankLabel={true}
                                    options={options} />}
                                cardbody={
                                    <GraphChart data={ticketData} options={ticketOptions} />
                                }
                            />
                        </div>


                        <div className="col-md-6">
                            <CustomCard
                                cardboldtitle={"All Tickets"}

                                cardbody={
                                    <AllTicketsChart data={data} options={optionsTicket} />
                                }
                            />
                        </div>

                        <div className="col-md-12">
                            <ComplaintDashboard
                                allComplainData={allComplainData}
                                complaintData={complaintData}
                                handlePageSubmit={handlePageSubmitt}
                                page={page}
                                pageLimit={pageLimit}
                                limit={limit}
                                limitChangeHandler={limitChangeHandlerr}
                                handleFilter={handleFilterr}
                                handleReset={handleResett}
                                handleView={(item) => navigate('/view-ticket', { state: { item } })}
                                handleEdit={(item) => navigate('/edit-ticket-end-user', { state: { item } })}
                            />
                        </div>

                    </div>
                </div>
            </div>
        </div >
    );
};

export default Ticket;
