import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./../modalStyle.scss";
import CustomInput from "../../CustomInput";
import CustomButton from "../../Button/Button";
import Customselect from "../../CustomSelect";

const AddSubCategory = ({show , Addcategory , onHideModal , category ,handlecategoryName ,catDropDownData , addSubCategory ,handleAddSubCat}) => {
    
  const handleSubmit =() =>{
    Addcategory()
  }

  return (
    <Modal show={show} centered className="sucess-content" onHide={() => onHideModal()} >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add Sub Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Customselect
          label={"Category"}
          value={category}
          options={catDropDownData}
          onChange={(e) => {handlecategoryName(e.target.value)}}
          placeholder={"Enter Category"}
        />
        <CustomInput
          label={"Sub Category"}
          value={addSubCategory}
          onChange={(e) => {handleAddSubCat(e.target.value)}}
          placeholder={"Enter Sub Category"}
        />

        <div className="text-center mt-3">
          <CustomButton
            styles={{ width: 120, height: 38, fontSize: 16 }}
            onClick={() => handleSubmit()}
            children={"Submit"}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddSubCategory;
