import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./../modalStyle.scss";
import CustomInput from "../../CustomInput";
import CustomButton from "../../Button/Button";
import Customselect from "../../CustomSelect";

const EditDesignation = ({ show, editDesignation, onHideModal, editDesignationName, handleEditDesignationName, options, status, handleChangeStatus }) => {

    const handleSubmit = () => {
        editDesignation()
    }

    return (
        <Modal show={show} centered className="sucess-content" onHide={() => onHideModal()} >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Edit Designation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CustomInput
                    label={"Designation"}
                    value={editDesignationName}
                    onChange={(e) => { handleEditDesignationName(e.target.value) }}
                    placeholder={"Enter Designation"}
                />
                <Customselect
                    label={"Status"}
                    value={status}
                    options={options}
                    onChange={(e) => { handleChangeStatus(e.target.value) }}
                />
                <div className="text-center mt-3">
                    <CustomButton
                        styles={{ width: 120, height: 38, fontSize: 16 }}
                        onClick={() => handleSubmit()}
                        children={"Submit"}
                    />
                </div>

            </Modal.Body>
        </Modal>
    );
};

export default EditDesignation;
