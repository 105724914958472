import React from 'react';
import './style.scss';

function Textarea(props) {
    const { style, title, placeholder, readOnly,onChange } = props
    return (
        <div className='textarea_main_section'>
            <label className='input_label'>{props.label}{props.require && <span className="text-danger">*</span>}</label>
            <textarea
                className={[`textarea ${style}`]}
                id="textarea" name="textarea"
                readOnly={readOnly}
                placeholder={placeholder}
                onChange={onChange}
                style={{ backgroundColor: readOnly ? '#F0EFEF' : 'white' }}
                // defaultValue={defaultValue}
            >
                {title}
            </textarea>
        </div>
    )
}

export default Textarea;
