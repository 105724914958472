import React, { useState, useEffect } from 'react'
import Header from "../../components/Header";
import { useStores } from "../../stores";
import notify from "../../shared/notify";
import CustomCard from "../../components/CustomCard";
import CustomButton from "../../components/Button/Button";
import ResponsivePagination from "react-responsive-pagination";
import CustomInput from '../../components/CustomInput';
import Loader from '../../Helper/Loader';
import AddDepartment from '../../components/Modal/Department/addDepartment';
import EditDeparment from '../../components/Modal/Department/editDepartment';

const Department = () => {
    const { mainStore } = useStores()
    const [name, setName] = useState("")
    const [deparmentList, setDeparmentList] = useState([])
    const [totalPage, setTotalPage] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    let loginUserData = sessionStorage.getItem("userData");
    let parseuserData = JSON.parse(loginUserData);
    const [addDeparmentModal, setAddDepartmentModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [department, setDepartment] = useState("")
    const [editDepartmentName, setEditDepartmentName] = useState("")
    const [showEditModal, setShowEditModal] = useState(false)
    const [item, setItem] = useState()
    const [status, setStatus] = useState();



    useEffect(() => {
        fetchDepartment()
    }, [name])

    let options = [
        { value: "true", label: "Active" },
        { value: "false", label: "Inactive" },
    ];



    const fetchDepartment = async (page) => {
        let payload = {
            pageNumber: page ? page : 1,
            limit: 10,
            filters: {},
        };
        if (name) {
            payload.filters.department_name = name;
        }
        setIsLoading(true)
        await mainStore.getAllFilterDepartment(
            payload,
            (response) => {
                if (response?.data?.meta?.status === "success") {
                    setDeparmentList(response?.data?.data?.filteredData?.results)
                    setTotalPage(response?.data?.data?.totalPages);
                    setIsLoading(false)
                } else {
                    setDeparmentList([]);
                    setTotalPage(null);
                    notify(response?.data?.meta?.message, "danger");
                    setIsLoading(false)
                }
            },
            (error) => {
                setDeparmentList([]);
                setTotalPage(null);
                notify(error?.response?.data?.meta?.message, "danger");
                setIsLoading(false)
            }
        );
    };

    const handleEdit = (item) => {
        console.log(item);
        setEditDepartmentName(item?.department_name)
        setStatus(item?.is_active)
        setShowEditModal(true)
        setItem(item)
    }

    const handleChangeStatus = (e) => {
        setStatus(e)
    }

    const handlePageChange = (pageNumber) => {
        fetchDepartment(pageNumber);
        setCurrentPage(pageNumber);
    }

    const handleDeparmentNamee = (e) => {
        setDepartment(e)
    }
    const handleEditDeparmentNamee = (e) => {
        setEditDepartmentName(e)
    }


    const addDeparmentt = async () => {
        let payload = {
            department_name: department
        }
        setIsLoading(true)
        await mainStore.addDepartment(payload, (res) => {
            if (res?.data?.meta?.status === "success") {
                notify(res?.data?.meta?.message)
                setIsLoading(false)
                setAddDepartmentModal(false)
                setDepartment("")
                fetchDepartment()
            } else {
                notify(res?.data?.meta?.message, "danger")
                setIsLoading(false)
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
            setIsLoading(false)
        })
    }

    const editDeparment = async () => {
        let payload = {
            department_name: editDepartmentName,
            is_active: status
        }
        setIsLoading(true)
        await mainStore.editDepartment(item?.department_id ,payload, (res) => {
            if (res?.data?.meta?.status === "success") {
                notify(res?.data?.meta?.message)
                fetchDepartment()
                setIsLoading(false)
                setShowEditModal(false)
            } else {
                notify(res?.data?.meta?.message, "danger")
                setIsLoading(false)
            }
        },(error) =>{
            notify(error?.response?.data?.meta?.message, "danger")
            setIsLoading(false)
        })

    }


    return (
        <React.Fragment>
            {isLoading && <Loader />}
            <div className="dashboard-container">
                <div className="right-view">
                    <Header title={`Welcome ${parseuserData?.name},`} />
                    <div className="mt-3">
                        <CustomCard
                            cardtitle={"Department"}
                            cardboldtitle={"List"}
                            searchName={
                                <CustomInput
                                    label={"Search Department Name"}
                                    placeholder={"Search Department Name"}
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value);
                                    }}
                                />
                            }
                            toprightview={
                                <CustomButton
                                    onClick={() => {
                                        setAddDepartmentModal(true)
                                    }}
                                    children={"Add Department"}
                                />
                            }
                            cardbody={
                                <div className="container-fluid px-0">
                                    <div class="table-responsive listing-table">
                                        <table class="table ">
                                            <thead class="table-customlight">
                                                <tr>
                                                    <th scope="col">S.NO</th>
                                                    <th scope="col">Deparment</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {deparmentList?.length > 0 &&
                                                    deparmentList.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <td>{index + 1}</td>
                                                                <td>{item?.department_name}</td>
                                                                <td>
                                                                    {item?.is_active ? "Active" : "Inactive"}
                                                                </td>
                                                                <td>
                                                                    <img
                                                                        onClick={() => handleEdit(item)}
                                                                        width={20}
                                                                        style={{ cursor: "pointer" }}
                                                                        src={require("../../assets/images/edit.png")}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </table>
                                        {deparmentList?.length == 0 && (
                                            <p className="page-not-found" style={{ height: 200 }}>
                                                No Records Found
                                            </p>
                                        )}
                                    </div>
                                    {deparmentList?.length > 0 && (
                                        <div className="pagination-container">
                                            <ResponsivePagination
                                                current={currentPage}
                                                total={totalPage}
                                                onPageChange={(e) => {
                                                    handlePageChange(e);
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            }
                        />
                    </div>
                    <AddDepartment
                        show={addDeparmentModal}
                        onHide={() =>
                            setAddDepartmentModal(false)
                        }
                        onHideModal={() => {
                            setAddDepartmentModal(false);
                            fetchDepartment();
                        }}
                        department={department}
                        handleDeparmentName={handleDeparmentNamee}
                        addDeparment={addDeparmentt}
                    />
                    <EditDeparment
                        show={showEditModal}
                        onHide={() =>
                            setShowEditModal(false)
                        }
                        onHideModal={() => {
                            setShowEditModal(false);
                            fetchDepartment();
                        }}
                        editDepartmentName={editDepartmentName}
                        handleEditDeparmentName={handleEditDeparmentNamee}
                        editDeparment={editDeparment}
                        options={options}
                        status={status}
                        handleChangeStatus={handleChangeStatus}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default Department