import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./../modalStyle.scss";
import CustomInput from "../../CustomInput";
import CustomButton from "../../Button/Button";
import Customselect from "../../CustomSelect";
import { useStores } from "../../../stores";
import notify from "../../../shared/notify";

let options = [
  { value: "true", label: "Active" },
  { value: "false", label: "Inactive" },
];

const EditEntityModal = (props) => {
  const { entityData, roles } = props;
  const { mainStore } = useStores();
  const [name, setName] = useState(entityData?.name);
  const [entityCode, setEntityCode] = useState(entityData?.entity_code);
  const [status, setStatus] = useState(entityData?.isActive);

  const editEntityFun = async () => {
    if (name === "undefined" || name === "" || name === "null") {
      return notify("Please Enter Name", "danger");
    }
    if (entityCode === "undefined" || entityCode === "" || entityCode === "null") {
      return notify("Please Enter Entity Code", "danger");
    }

    if (status === "undefined" || status === "" || status === "null") {
      return notify("Please Select Status", "danger");
    }

    let payload = {
      name: name,
      entityCode,
      isActive: status,
    };

    await mainStore.editEntity(
      entityData?.id,
      payload,
      (response) => {
        if (response?.data?.meta?.status === "success") {
          console.log("response?.data", response?.data);
          notify(response?.data?.meta?.status);
          props.onHideModal();
        } else {
          notify(response?.data?.meta?.message, "danger");
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
      }
    );
  };

  return (
    <Modal {...props} centered className="sucess-content">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Entity
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CustomInput
          label={"Name"}
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          placeholder={"Enter Name"}
        />
        <CustomInput
          label={"Entity Code"}
          value={entityCode}
          onChange={(e) => {
            setEntityCode(e.target.value);
          }}
          placeholder={"Enter Entity Code"}
        />
        <Customselect
          label={"Status"}
          value={status}
          options={options}
          onChange={(e) => {
            setStatus(e.target.value);
          }}
        />
        <div className="text-center mt-3">
          <CustomButton
            styles={{ width: 120, height: 38, fontSize: 16 }}
            onClick={() => {
              editEntityFun();
            }}
            children={"Submit"}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditEntityModal;
