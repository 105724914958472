import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { useStores } from "../../stores";
import notify from "../../shared/notify";
import CustomCard from "../../components/CustomCard";
import CustomButton from "../../components/Button/Button";
import AddUserModal from "../../components/Modal/addUserModal";
import EditUserModal from "../../components/Modal/editUserModal";
import ResponsivePagination from "react-responsive-pagination";
// import UploadExcelModal from "../../components/Modal/uploadExcelModal";
import Loader from "../../Helper/Loader";
import unblockimage from "../../assets/images/unblockimage.png"
import blockimage from "../../assets/images/blockedimage.png"

import './style.scss'
import Business from "../Business";

const Dashboard = (props) => {
  const { mainStore } = useStores();
  const [userList, setUserList] = useState([]);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  // const [showUploadExcelModal, setShowUploadExcelModal] = useState(false);
  const [userData, setUserData] = useState(false);
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [totalPage, setTotalPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  let loginUserData = sessionStorage.getItem("userData");
  let parseuserData = JSON.parse(loginUserData);
  const [departmentData, setDepartmentData] = useState([])
  const [designationData, setDesignationData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [businessList, setBusinessList] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [subCategoryList, setSubCategoryList] = useState([])
  const [reportingManager, setReportingManager] = useState([])

  const [selectedCat, setSelectedCat] = useState([]);
  const [selectedSubCat, setSelectedSubCat] = useState();
 

  useEffect(() => {
    getUsersData();
    fetchRolesList();
    fetchDepartment()
    fetchDesignation()
    getAllBusiness()
    fetchCategory()
    fetchReportingManager()
  }, [])


  const getAllBusiness = async () => {
    await mainStore.fetchBusiness((status, res) => {
      if (res?.meta?.status === "success") {
        let BusinessData = res?.data?.businesses;
        let business = [];
        for (let i = 0; i < BusinessData?.length; i++) {
          business.push({ label: BusinessData[i].business_name, value: BusinessData[i].business_id });
        }
        setBusinessList(business)
      } else {
        notify(res?.meta?.message, "danger")
      }
    }, (error) => {
      notify(error?.response?.data?.meta?.message, "danger")
    })
  }

  console.log(selectedSubCat,"subcat");
  console.log(userData?.subcategory, "userData.subcategory");

  
useEffect(() => {
  if (subCategoryList.length > 0 && userData?.subcategory) {
    const filteredsubcategory = subCategoryList.filter((cat) => {
      return userData.subcategory?.some(
        (userRole) => userRole.subcategory_id === cat.value
      );
    });
    setSelectedSubCat(filteredsubcategory);
  }
}, [subCategoryList, userData]);


  const getUsersData = async (page) => {
    let payload = {
      pageNumber: page ? page : 1,
      limit: 10,
      filters: {},
    };
    setIsLoading(true)
    await mainStore.getUsers(
      payload,
      (response) => {
        if (response?.data?.meta?.status === "success") {
          setUserList(response?.data?.data?.filteredData?.results);
          setTotalPage(response?.data?.data?.totalPages);
          setIsLoading(false)
        } else {
          setUserList([]);
          setTotalPage(null);
          notify(response?.data?.meta?.message, "danger");
          setIsLoading(false)
        }
      },
      (error) => {
        setUserList([]);
        setTotalPage(null);
        notify(error?.response?.data?.meta?.message, "danger");
        setIsLoading(false)
      }
    );
  }


  const fetchDepartment = async () => {
    await mainStore.getAllDepartment((status, response) => {
      if (response?.meta?.status === "success") {
        let departList = response?.data?.departments;
        let department = [];
        for (let i = 0; i < departList?.length; i++) {
          department.push({ label: departList[i].department_name, value: departList[i].department_id });
        }
        setDepartmentData(department)
      } else {
        notify(response?.meta?.message, "danger")
      }
    }, (error) => {
      notify(error?.response?.data?.meta?.message, "danger")
    })
  }


  const fetchDesignation = async () => {
    await mainStore.getAllDesignation((status, response) => {
      if (response?.meta?.status === "success") {
        let desgList = response?.data?.designation;
        let designation = [];
        for (let i = 0; i < desgList?.length; i++) {
          designation.push({ label: desgList[i].designation_name, value: desgList[i].designation_id });
        }
        setDesignationData(designation)
      } else {
        notify(response?.meta?.message, "danger")
      }
    }, (error) => {
      notify(error?.response?.data?.meta?.message, "danger")
    })
  }

  const fetchUserData = async (id) => {
    debugger
    await mainStore.getUserData(
      id,
      (status, response) => {
        if (response?.meta?.status === "success") {
          let userData = response?.data?.user;
          const filteredRoles = roles.filter((role) => {
            return userData.userRoles.some(
              (userRole) => userRole.role_id === role.value
            );
          });
          const filteredcategory = categoryList.filter((cat) => {
            return userData.category?.some(
              (userRole) => userRole.category_id === cat.value
            );
          });
          // const filteredsubcategory = subCategoryList.filter((cat) => {
          //   return userData.subcategory?.some(
          //     (userRole) => userRole.subcategory_id === cat.value
          //   );
          // });
          const filteredsubcategory = subCategoryList.filter((subCat) => {
            return userData.subcategory?.some(
              (userSubCat) => userSubCat.subcategory_id === subCat.value
            );
          });
          
          console.log(filteredcategory, "filteredcategory");
          console.log(filteredsubcategory, "filteredsubcategory");
          console.log(filteredRoles, "filteredRoles");
          
          
          setSelectedSubCat(filteredsubcategory)
          setSelectedCat(filteredcategory)
          setSelectedRoles(filteredRoles);
          setUserData(userData);
          setShowEditUserModal(true);
        } else {
          notify(response?.data?.meta?.message, "danger");
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
      }
    );
  };

  const fetchRolesList = async () => {
    await mainStore.getRoles(
      (status, response) => {
        if (response?.status === "success") {
          let roleList = response?.data?.roles;
          let roles = [];
          for (let i = 0; i < roleList.length; i++) {
            roles.push({ label: roleList[i].name, value: roleList[i].id });
          }
          setRoles(roles);
        } else {
          notify(response?.data?.meta?.message, "danger");
          setRoles([]);
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        setRoles([]);
      }
    );
  };

  const handlePageChange = (pageNumber) => {
    getUsersData(pageNumber);
    setCurrentPage(pageNumber);
  };

  const handleBlockUser = (data) => {
    let userId = data?.id;
    let payload = {
      isActive: !data.isActive
    }

    mainStore.blockUser(userId, payload, (response) => {
      getUsersData();
    });
  };

  const fetchCategory = async () => {
    await mainStore.getAllCategoryWithoutFilter((status, response) => {
      if (response?.meta?.status === "success") {
        let data = response?.data?.categories;
        let category = [];
        for (let i = 0; i < data?.length; i++) {
          category.push({ label: data[i].category_name, value: data[i].category_id });
        }
        setCategoryList(category)
      } else {
        notify(response?.meta?.message, "danger")
      }
    }, (error) => {
      notify(error?.response?.data?.meta?.message, "danger")
    })
  }

  const fetchSubCategory = async (item) => {
    let payload = {
      multupleCategoryIds: item
    };
    setIsLoading(true)
    await mainStore.getSubCategoryWithoutFilter(payload, (response) => {
      if (response?.data?.meta?.status === "success") {
        let data = response?.data?.data?.filteredData;
        let category = [];
        for (let i = 0; i < data?.length; i++) {
          category.push({ label: data[i].subcategory_name, value: data[i].subcategory_id});
        }
        setSubCategoryList(category)
        setIsLoading(false)
      } else {
        setSubCategoryList([])
        notify(response?.data?.meta?.message, "danger");
        setIsLoading(false)
      }
    },
      (error) => {
        setSubCategoryList([])
        notify(error?.response?.data?.meta?.message, "danger");
        setIsLoading(false)
      }
    );
  }

  const fetchReportingManager = async () => {
    await mainStore.getAllReportingManager((status, response) => {
      if (response?.meta?.status === "success") {
        let data = response?.data?.allReportingManagers;
        let category = [];
        for (let i = 0; i < data?.length; i++) {
          category.push({ label: data[i].name, value: data[i].id });
        }
        setReportingManager(category)
      } else {
        notify(response?.meta?.message, "danger")
      }
    }, (error) => {
      notify(error?.response?.data?.meta?.message, "danger")
    })
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="dashboard-container">
        <div className="right-view">
          <Header title={`Welcome ${parseuserData?.name},`} />
          <div className="mt-3">
            <CustomCard
              cardtitle={"Users"}
              cardboldtitle={"List"}
              toprightview={
                <>
                  <CustomButton
                    onClick={() => {
                      setShowAddUserModal(true);
                    }}
                    children={"Add User"}
                  />
                </>
              }
              cardbody={
                <div className="container-fluid px-0">
                  <div class="table-responsive listing-table">
                    <table class="table ">
                      <thead class="table-customlight">
                        <tr>
                          <th scope="col">Emp Code</th>
                          <th scope="col">Name</th>
                          <th scope="col">Contact</th>
                          <th scope="col">Email</th>
                          <th scope="col">Department</th>
                          <th scope="col">Line Of Service</th>
                          <th scope="col">Status</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userList?.length > 0 &&
                          userList.map((user) => {
                            return (
                              <tr>
                                <td>{user?.emp_code}</td>
                                <td>{user?.name}</td>
                                <td>{user?.contact}</td>
                                <td>{user?.email}</td>
                                <td>{user?.user_department}</td>
                                <td>{user?.line_of_service}</td>
                                <td><img
                                  className={"user-status-img"}
                                  onClick={() => { handleBlockUser(user) }}
                                  src={user?.isActive ? unblockimage : blockimage} /></td>
                                <td>
                                  <img
                                    onClick={async (e) => {
                                      e.preventDefault();
                                      await fetchUserData(user?.id);
                                   
                                    }}
                                    width={20}
                                    style={{ cursor: "pointer" }}
                                    src={require("../../assets/images/edit.png")}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  {userList?.length == 0 && (
                    <p className="page-not-found" style={{ height: 200 }}>
                      No Records Found
                    </p>
                  )}
                  {userList?.length > 0 && (
                    <div className="pagination-container">
                      <ResponsivePagination
                        current={currentPage}
                        total={totalPage}
                        onPageChange={(e) => {
                          handlePageChange(e);
                        }}
                      />
                    </div>
                  )}
                </div>
              }
            />
          </div>
        </div>
        <AddUserModal
          show={showAddUserModal}
          onHide={() => {
            setShowAddUserModal(false);
          }}
          onHideModal={() => {
            setShowAddUserModal(false);
            getUsersData();
          }}
          roles={roles}
          departmentData={departmentData}
          designationData={designationData}
          businessList={businessList}
          categoryList={categoryList}
          fetchSubCategory={fetchSubCategory}
          subCategoryList={subCategoryList}
          reportingManager={reportingManager}
        />
        {showEditUserModal && <EditUserModal

          businessList={businessList}
          categoryList={categoryList}
          subCategoryList={subCategoryList}
          fetchSubCategory={fetchSubCategory}
          departmentData={departmentData}
          designationData={designationData}
          show={showEditUserModal}
          reportingManager={reportingManager}
          subcategoryList={subCategoryList}
          onHide={() => {
            setShowEditUserModal(false);
            getUsersData();
          }}
          onHideModal={() => {
            setShowEditUserModal(false);
            getUsersData();
          }}
          userData={userData}
          roles={roles}
          selectedRoles={selectedRoles}
          key={userData?.id}
          selectedCat={selectedCat}
          selectedSubCat={selectedSubCat}
        />}
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
