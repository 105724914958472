import React from "react";
import "./customCard.scss";

const CustomCard = (props) => {
  return (
    <div className="card-container">
      <div className="card-view">
        <div className="top-border"></div>
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-7 mb-4">
              <h3 className="card-title">
                {props.cardtitle}{" "}
                <span className="bolder-font">{props.cardboldtitle}</span>
              </h3>
              {!props.underline && <div className="heading-underline"></div>}
            </div>
            <div className="col-md-5 text-end">{props.toprightview}</div>
            <div className="col-md-4 col-lg-3">{props.searchName}</div>
            <div className="col-md-12">{props.search}</div>
          </div>
          {props.cardbody}
        </div>
      </div>
    </div>
  );
};

export default CustomCard;
