import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./../modalStyle.scss";
import CustomInput from "../../CustomInput";
import CustomButton from "../../Button/Button";
import Customselect from "../../CustomSelect";
import { useStores } from "../../../stores";
import notify from "../../../shared/notify";

let options = [
  { value: "true", label: "Active" },
  { value: "false", label: "Inactive" },
];

const EditEntityModal = (props) => {
  const { bankData, entitiesList } = props;
  const { mainStore } = useStores();
  const [bankName, setBankName] = useState(bankData?.bank_name);
  // const [entityCode, setEntityCode] = useState("");
  const [selectedEntity, setSelectedEntity] = useState(bankData?.entity_code)
  const [bankAccountNumber, setBankAccountNumber] = useState(bankData?.bank_account_number);
  const [glCode, setGlCode] = useState(bankData?.glcode)
  const [status, setStatus] = useState(bankData?.is_active);

  const editBankDetails = async () => {
    if (bankName === "undefined" || bankName === "" || bankName === "null") {
      return notify("Please Enter Name", "danger");
    }
    if (bankAccountNumber === "undefined" || bankAccountNumber === "" || bankAccountNumber === "null") {
      return notify("Please Enter Bank Account Number", "danger");
    }
    if (glCode === "undefined" || glCode === "" || glCode === "null") {
      return notify("Please Gl Code", "danger");
    }

    if (selectedEntity === "undefined" || selectedEntity === "" || selectedEntity === "null") {
      return notify("Please Enter Entity code", "danger");
    }

    let payload = {
      bankName: bankName,
      entityCode: selectedEntity,
      bankAccountNumber: bankAccountNumber,
      glCode: glCode,
      isActive: status
    }


    await mainStore.editBankData(
      bankData?.id,
      payload,
      (response) => {
        if (response?.data?.meta?.status === "success") {
          console.log("response?.data", response?.data);
          notify(response?.data?.meta?.status);
          props.onHideModal();
        } else {
          notify(response?.data?.meta?.message, "danger");
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
      }
    );
  };

  return (
    <Modal {...props} centered className="sucess-content">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Bank Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-4">
        <Customselect
          multiSelect={false}
          label={"Entities"}
          value={selectedEntity}
          options={entitiesList}
          defaultValue={selectedEntity}
          onChange={(e) => {
            setSelectedEntity(e.target.value);
          }}
        />
        </div>
        <div className="col-md-4">
        <CustomInput
          label={"Bank Name"}
          value={bankName}
          onChange={(e) => {
            setBankName(e.target.value);
          }}
          placeholder={"Enter Bank Name"}
        />
        </div>

          <div className="col-md-4">
        <CustomInput
          label={"Bank Account Number"}
          value={bankAccountNumber}
          onChange={(e) => {
            setBankAccountNumber(e.target.value);
          }}
          placeholder={"Enter Bank Account Number"}
        />
        </div>

          <div className="col-md-4">
        <CustomInput
          label={"Gl Code"}
          value={glCode}
          onChange={(e) => {
            setGlCode(e.target.value);
          }}
          placeholder={"Enter Gl Code"}
        />
        </div>

          <div className="col-md-4">
        <Customselect
          label={"Status"}
          value={status}
          options={options}
          onChange={(e) => {
            setStatus(e.target.value);
          }}
        />
        </div>

        <div className="text-center mt-3">
          <CustomButton
            styles={{ width: 120, height: 38, fontSize: 16 }}
            onClick={() => {
              editBankDetails();
            }}
            children={"Submit"}
          />
        </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditEntityModal;
