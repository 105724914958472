// import React, { useState, useEffect } from "react";
// import Header from "../../components/Header";
// import Loader from "../../Helper/Loader";
// import { useNavigate } from "react-router-dom";
// import { useStores } from "../../stores";
// import notify from "../../shared/notify";
// import ComplaintDashboard from "../../components/ComplaintDashboard";


// const limit = [
//     {
//         option: 10,
//         value: 10,
//     },
//     {
//         option: 100,
//         value: 100,
//     },
//     {
//         option: 500,
//         value: 500,
//     },
//     {
//         option: 1000,
//         value: 1000,
//     },
// ];

// const ViewAll = () => {
//     const [isLoading, setIsLoading] = useState(false);
//     const [complaintData, setComplaintData] = useState([])
//     const [allComplainData, setAllComplainData] = useState([])
//     const navigate = useNavigate();
//     const { mainStore } = useStores()
//     const [page, setPage] = useState(1)
//     const [pageLimit, setPageLimit] = useState(10)
//     const [categoryList, setCategoryList] = useState([]);
//     const [selectCategory, setSelectCategory] = useState("")
//     const [priorityList, setPriorityList] = useState([]);
//     const [selectedPriority, setSelectedPriority] = useState("")


//     useEffect(() => {
//         FetchedComplain()
//         fetchAllCategories()
//         getPriorities()
//     }, [])

//     const FetchedComplain = async () => {
//         let payload = {
//             limit: pageLimit,
//             filters: {
//             },
//             pageNumber: page
//         }
//         CallFetchedComplain(payload)

//     }

//     const CallFetchedComplain = async (payload) => {
//         setIsLoading(true)
//         await mainStore.getAllCmsComplaints(payload, (res) => {
//             if (res?.data?.meta?.status === "success") {
//                 setComplaintData(res?.data?.data?.filteredData?.results)
//                 setAllComplainData(res?.data?.data)
//                 setIsLoading(false)
//             }

//         }, (err) => {
//             notify(err?.response?.data?.meta?.message, "danger")
//             setIsLoading(false)
//         })
//     }

//     const handlePageSubmitt = async (index) => {
//         setPage(index.selected + 1);
//         let payload = {
//             limit: pageLimit,
//             filters: {},
//             pageNumber: index.selected + 1,
//         };
//         CallFetchedComplain(payload);
//     };

//     const limitChangeHandlerr = async (index) => {
//         setPageLimit(index);
//         let payload = {
//             limit: index,
//             filters: {},
//             pageNumber: page,
//         };
//         CallFetchedComplain(payload);
//     };

//     const handleFilterr = async () => {
//         let payload = {
//             limit: pageLimit,
//             filters: {
//             },
//             pageNumber: page,
//         };

//         if (selectedPriority) {
//             payload.filters.priority_id = selectedPriority
//         }
//         if (selectCategory) {
//             payload.filters.category_id = selectCategory
//         }
//         CallFetchedComplain(payload);
//     };

//     const handleResett = async () => {
//         setSelectCategory("")
//         setSelectedPriority("")
//         setPage(1);
//         setPageLimit(10);
//         await FetchedComplain();
//     };

//     const fetchAllCategories = () => {
//         setIsLoading(true)
//         mainStore.getAllCategories((status, res) => {
//             if (res?.meta?.status === "success") {
//                 let cat = res?.data?.categories;
//                 let data = [];
//                 for (let i = 0; i < cat.length; i++) {
//                     data.push({ label: cat[i].category_name, value: cat[i].category_id });
//                 }
//                 setCategoryList(data);
//                 setIsLoading(false)
//             } else {
//                 setIsLoading(false)
//             }
//         },
//             (error) => {
//                 notify(error?.response?.data?.meta?.message, "danger")
//                 setIsLoading(false)
//             })
//     }
//     const getPriorities = () => {
//         mainStore.getPriorityNoFilters((status, res) => {
//             if (res?.meta?.status === "success") {
//                 let priority = res?.data?.getAllComplaintPriorityLevels;
//                 let data = [];
//                 for (let i = 0; i < priority.length; i++) {
//                     data.push({ label: priority[i].priority_name, value: priority[i].priority_id });
//                 }
//                 setPriorityList(data)
//             } else {
//                 console.log(res?.data?.meta?.message, "setDeparmentList")
//             }
//         })
//     }



//     return (
//         <div className="main-container main-container dashboard_container_crm">
//             {isLoading && <Loader />}
//             <div className="right-view">
//                 <Header title={"Tickets,"} />
//                 <div className="container-fluid px-0">
//                     <ComplaintDashboard
//                         allComplainData={allComplainData}
//                         complaintData={complaintData}
//                         handlePageSubmit={handlePageSubmitt}
//                         page={page}
//                         pageLimit={pageLimit}
//                         limit={limit}
//                         limitChangeHandler={limitChangeHandlerr}
//                         handleFilter={handleFilterr}
//                         handleReset={handleResett}
//                         handleView={(item) => navigate('/view-ticket', { state: { item } })}
//                         handleEdit={(item) => navigate('/edit-ticket-end-user', { state: { item } })}
//                         categoryList={categoryList}
//                         selectCategory={selectCategory}
//                         handleSelectCategory={(e) => setSelectCategory(e)}
//                         handlePriority={(e) => setSelectedPriority(e)}
//                         selectedPriority={selectedPriority}
//                         priorityList={priorityList}
//                     />
//                 </div>
//             </div>
//         </div >
//     );
// };

// export default ViewAll;

import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Loader from "../../Helper/Loader";
import { useNavigate } from "react-router-dom";
import { useStores } from "../../stores";
import notify from "../../shared/notify";
import ComplaintDashboard from "../../components/ComplaintDashboard";

const limit = [
    { option: 10, value: 10 },
    { option: 100, value: 100 },
    { option: 500, value: 500 },
    { option: 1000, value: 1000 },
];

const ViewAll = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [complaintData, setComplaintData] = useState([]);
    const [allComplainData, setAllComplainData] = useState([]);
    const navigate = useNavigate();
    const { mainStore } = useStores();
    const [page, setPage] = useState(parseInt(sessionStorage.getItem('currentPage')) || 1);
    const [pageLimit, setPageLimit] = useState(parseInt(sessionStorage.getItem('pageLimit')) || 10);
    const [categoryList, setCategoryList] = useState([]);
    const [selectCategory, setSelectCategory] = useState(sessionStorage.getItem('selectedCategory') || "");
    const [priorityList, setPriorityList] = useState([]);
    const [selectedPriority, setSelectedPriority] = useState(sessionStorage.getItem('selectedPriority') || "");

    useEffect(() => {
        FetchedComplain();
        fetchAllCategories();
        getPriorities();
    }, [page, pageLimit]);

    const FetchedComplain = async () => {
        let payload = {
            limit: pageLimit,
            filters: {},
            pageNumber: page,
        };

        if (selectedPriority) {
            payload.filters.priority_id = selectedPriority;
        }
        if (selectCategory) {
            payload.filters.category_id = selectCategory;
        }

        CallFetchedComplain(payload);
    };

    const CallFetchedComplain = async (payload) => {
        setIsLoading(true);
        await mainStore.getAllCmsComplaints(payload, (res) => {
            if (res?.data?.meta?.status === "success") {
                setComplaintData(res?.data?.data?.filteredData?.results);
                setAllComplainData(res?.data?.data);
            }
            setIsLoading(false);
        }, (err) => {
            notify(err?.response?.data?.meta?.message, "danger");
            setIsLoading(false);
        });
    };

    const handlePageSubmitt = async (index) => {
        const newPage = index.selected + 1;
        setPage(newPage);
        sessionStorage.setItem('currentPage', newPage);
        FetchedComplain();
    };

    const limitChangeHandlerr = async (index) => {
        setPageLimit(index);
        sessionStorage.setItem('pageLimit', index);
        FetchedComplain();
    };

    const handleFilterr = async () => {
        sessionStorage.setItem('selectedCategory', selectCategory);
        sessionStorage.setItem('selectedPriority', selectedPriority);
        FetchedComplain();
    };

    const handleResett = async () => {
        setSelectCategory("");
        setSelectedPriority("");
        setPage(1);
        setPageLimit(10);
        sessionStorage.removeItem('selectedCategory');
        sessionStorage.removeItem('selectedPriority');
        sessionStorage.removeItem('currentPage');
        sessionStorage.removeItem('pageLimit');
        let payload = {
            limit: 10,
            filters: {
            },
            pageNumber: 1,
        };

        CallFetchedComplain(payload);
    };

    const fetchAllCategories = () => {
        setIsLoading(true)
        mainStore.getAllCategories((status, res) => {
            if (res?.meta?.status === "success") {
                let cat = res?.data?.categories;
                let data = [];
                for (let i = 0; i < cat.length; i++) {
                    data.push({ label: cat[i].category_name, value: cat[i].category_id });
                }
                setCategoryList(data);
                setIsLoading(false)
            } else {
                setIsLoading(false)
            }
        },
            (error) => {
                notify(error?.response?.data?.meta?.message, "danger")
                setIsLoading(false)
            })
    }
    const getPriorities = () => {
        mainStore.getPriorityNoFilters((status, res) => {
            if (res?.meta?.status === "success") {
                let priority = res?.data?.getAllComplaintPriorityLevels;
                let data = [];
                for (let i = 0; i < priority.length; i++) {
                    data.push({ label: priority[i].priority_name, value: priority[i].priority_id });
                }
                setPriorityList(data)
            } else {
                console.log(res?.data?.meta?.message, "setDeparmentList")
            }
        })
    }

    return (
        <div className="main-container main-container dashboard_container_crm">
            {isLoading && <Loader />}
            <div className="right-view">
                <Header title={"Tickets,"} />
                <div className="container-fluid px-0">
                    <ComplaintDashboard
                        allComplainData={allComplainData}
                        complaintData={complaintData}
                        handlePageSubmit={handlePageSubmitt}
                        page={page}
                        pageLimit={pageLimit}
                        limit={limit}
                        limitChangeHandler={limitChangeHandlerr}
                        handleFilter={handleFilterr}
                        handleReset={handleResett}
                        handleView={(item) => navigate('/view-ticket', { state: { item } })}
                        handleEdit={(item) => navigate('/edit-ticket-end-user', { state: { item } })}
                        categoryList={categoryList}
                        selectCategory={selectCategory}
                        handleSelectCategory={(e) => setSelectCategory(e)}
                        handlePriority={(e) => setSelectedPriority(e)}
                        selectedPriority={selectedPriority}
                        priorityList={priorityList}
                        FetchedComplain={FetchedComplain}
                    />
                </div>
            </div>
        </div>
    );
};

export default ViewAll;
