import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./../modalStyle.scss";
import CustomInput from "../../CustomInput";
import CustomButton from "../../Button/Button";
import Customselect from "../../CustomSelect";

const EditPlantName = ({ show, UpdatePlantName, onHideModal, editPlantName, handlePlantName, options, status, entityName,
    handleChangeStatus, handleSetEntityName, entityList, handleEditLocation, editPlantOfLocation }) => {

    const handleSubmit = () => {
        UpdatePlantName()
    }

    return (
        <Modal show={show} centered className="sucess-content" onHide={() => onHideModal()} >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Edit Plant Name</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-6">
                        <Customselect
                            label="Entity"
                            options={entityList}
                            value={entityName}
                            onChange={(e) => handleSetEntityName(e.target.value)}
                        />
                    </div>
                    <div className="col-md-6">
                        <CustomInput
                            label={"Plant Name"}
                            value={editPlantName}
                            onChange={(e) => { handlePlantName(e.target.value) }}
                            placeholder={"Enter Plant Name"}
                        />
                    </div>
                    <div className="col-md-6">
                    <CustomInput
                        label={"Plant Location"}
                        value={editPlantOfLocation}
                        onChange={(e) => { handleEditLocation(e.target.value) }}
                        placeholder={"Enter Plant Location"}
                    />
                </div>
                <div className="col-md-6">
                <Customselect
                    label={"Status"}
                    value={status}
                    options={options}
                    onChange={(e) => { handleChangeStatus(e.target.value) }}
                />
            </div>
            <div className="text-center mt-3">
                <CustomButton
                    styles={{ width: 120, height: 38, fontSize: 16 }}
                    onClick={() => handleSubmit()}
                    children={"Submit"}
                />
            </div>
        </div>
            </Modal.Body >
        </Modal >
    );
};

export default EditPlantName;
