import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./modalStyle.scss";
import CustomInput from "../CustomInput";
import CustomButton from "../Button/Button";
import Customselect from "../CustomSelect";
import { useStores } from "../../stores";
import notify from "../../shared/notify";
import ValidateEmail from "../../Helper/emailValidation";
import Loader from "../../Helper/Loader";
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";
import { json } from "react-router-dom";

// let options = [
//   { value: "Finance", label: "Finance" },
//   { value: "Purchase", label: "Purchase" },
// ];
let LINE_OF_SERVICES = [
  { value: "Solar", label: "Solar" },
  { value: "Solar Module Line", label: "Solar Module Line" },
  { value: "Biomass (Punjab & Haryana)", label: "Biomass (Punjab & Haryana)" },
  { value: "Biomass- Rajasthan", label: "Biomass- Rajasthan" },
  { value: "HO", label: "HO" },
];


const AddUserModal = (props) => {
  const { roles } = props;
  console.log(roles, "designationData");
  const { mainStore } = useStores();
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [empCode, setEmpCode] = useState("");
  const [password, setPassword] = useState("");
  const [userBusiness, setUserBusiness] = useState("")
  const [userDepartment, setUserDepartment] = useState("");
  const [userDesignation, setUserDesignation] = useState("");
  const [selectedRoles, setSelectedRoles] = useState(null);
  const [rolesList, setRolesList] = useState([])
  const [selectedLineOfService, setSelectedLineOfService] = useState(null);
  const [ischecked, setIsChecked] = useState(false)
  const [dateOfJoining, setDateOfJoining] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [selectedSubCategory, setSelectedSubCategory] = useState([])
  const [selectedCategory, setSelectedCategory] = useState("")
  const [selectedReportingManager , setSelectedReportingManager] = useState("")

  const result = props?.subCategoryList.filter((ele) => selectedSubCategory.includes(ele.value)).map((ele) => ({ subcategory_id: ele.value, category_id: ele.category_id }))
  console.log(result,"resiulttttttttt")  



  useEffect(() => {
    //  purchase deparment that id is 7
    if (userDepartment == 7) {
      let rolesData = roles.filter(obj => obj.label !== "Treasury");
      setRolesList(rolesData)

    }
    if (userDepartment !== "7") {
      setRolesList(roles)
    }

  }, [userDepartment])


  const findUserDeparmentData = () => {
    let Data;
    Data = props?.departmentData.find((elem) => elem.value == userDepartment)
    if (Data?.label == "finance") {
      return "Finance"
    } else if (Data?.label == "purchase") {
      return "Purchase"
    }

    return Data?.label
  }


  const addUser = async () => {
    if (name === "undefined" || name === "" || name === "null") {
      return notify("Please Enter Name", "danger");
    }
    if (contact === "undefined" || contact === "" || contact === "null") {
      return notify("Please Enter Contact", "danger");
    }
    if (email === "undefined" || email === "" || email === "null") {
      return notify("Please Enter Email", "danger");
    }
    if (empCode === "undefined" || empCode === "" || empCode === "null") {
      return notify("Please Enter Emp Code", "danger");
    }
    if (password === "undefined" || password === "" || password === "null") {
      return notify("Please Enter Password", "danger");
    }
    if (userDepartment === "undefined" || userDepartment === "" || userDepartment === "null") {
      return notify("Please Select Department", "danger");
    }
    if (selectedRoles?.length == 0) {
      return notify("Please Select Roles", "danger");
    }
    if (selectedLineOfService === "undefined" || selectedLineOfService === "" || selectedLineOfService === null) {
      return notify("Please Select Line of Service", "danger");
    }
    let payload = {
      name: name,
      contact: contact,
      email: email,
      empCode: empCode,
      roles: selectedRoles,
      password: password,
      userDepartment: findUserDeparmentData(),
      lineOfService: selectedLineOfService,
      is_interviewer: ischecked,
      department_id: userDepartment,
      designation_id: userDesignation,
      date_of_joining: dateOfJoining,
      business_id: userBusiness,
      category_id: selectedCategory,
      subcategory_id: selectedSubCategory,
      reporting_manager:selectedReportingManager
    };
    setIsLoading(true)
    debugger
    await mainStore.addUsers(
      payload,
      (response) => {
        if (response?.data?.meta?.status === "success") {
          notify(response?.data?.meta?.message);
          setName("");
          setContact("");
          setEmail("");
          setEmpCode("");
          setSelectedRoles(null);
          setPassword("");
          setUserDepartment(null)
          setSelectedLineOfService(null)
          setSelectedCategory("")
          setSelectedReportingManager("")
          setSelectedReportingManager("")
          props.onHideModal();
          setIsLoading(false)
        } else {
          notify(response?.data?.meta?.message, "danger");
          setIsLoading(false)
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        setIsLoading(false)
      }
    );
  };

  const handleEmailOnBlur = (e) => {
    let validate = ValidateEmail(e);
    if (validate === false) {
      notify("Please Enter A Valid Email ID", "danger");
      setEmail("");
      return;
    } else {
      setEmail(e);
    }
  };



  const handleCategory = (e) => {
    let item = [];
    for (let i = 0; i < e?.length; i++) {
      item.push(e[i].value);
    }
    setSelectedCategory(item);
    props?.fetchSubCategory(item)
  }

  const handleSubCategory = (e) => {
    let data = [];
    for (let i = 0; i < e.length; i++) {
      data.push(e[i].value);
    }
    setSelectedSubCategory(data)
  }







  return (
    <Modal {...props} centered className="sucess-content">
      {isLoading && <Loader />}
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-4">
            <CustomInput
              label={"Name"}
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              placeholder={"Enter Name"}
            />
          </div>
          <div className="col-md-4">
            <CustomInput
              label={"Contact"}
              value={contact}
              onChange={(e) => {
                if (e.target.value?.length < 11) {
                  setContact(e.target.value);
                }
              }}
              placeholder={"Enter Contact"}
            />
          </div>
          <div className="col-md-4">
            <CustomInput
              label={"Email"}
              value={email}
              onBlur={(e) => {
                handleEmailOnBlur(e.target.value);
              }}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder={"Enter Email"}
            />
          </div>
          <div className="col-md-4">
            <CustomInput
              label={"Emp Code"}
              value={empCode}
              onChange={(e) => {
                setEmpCode(e.target.value);
              }}
              placeholder={"Enter Emp Code"}
            />
          </div>
          <div className="col-md-4">
            <CustomInput
              label={"Password"}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder={"Enter Password"}
            />
          </div>
          <div className="col-md-4">
            <Customselect
              label={"Select Business"}
              value={userBusiness}
              options={props?.businessList}
              onChange={(e) => {
                setUserBusiness(e.target.value);
              }}
            />
          </div>
          <div className="col-md-4">
            <Customselect
              label={"Select Department"}
              value={userDepartment}
              options={props?.departmentData}
              onChange={(e) => {
                setUserDepartment(e.target.value);
              }}
            />
          </div>
          <div className="col-md-4">
            <Customselect
              label={"Select Designation"}
              value={userDesignation}
              options={props?.designationData}
              onChange={(e) => {
                setUserDesignation(e.target.value);
              }}
            />
          </div>
          <div className="col-md-4">
            <Customselect
              multiSelect={true}
              label={"Roles"}
              value={selectedRoles}
              options={userDepartment == "" ? roles : rolesList}
              defaultValue={selectedRoles}
              onChange={(e) => {
                let roles = [];
                for (let i = 0; i < e?.length; i++) {
                  roles.push(e[i].value);
                }
                setSelectedRoles(roles);
              }}
            />
          </div>
          <div className="col-md-4">
            <Customselect
              label={"Line Of Service"}
              value={selectedLineOfService}
              options={LINE_OF_SERVICES}
              onChange={(e) => {
                setSelectedLineOfService(e.target.value);
              }}
            />
          </div>
          <div className="col-md-4">
            <CustomInput
              label={"DOJ"}
              // require ={true}
              value={dateOfJoining}
              onChange={(e) => {
                setDateOfJoining(e.target.value);
              }}
              placeholder={"DOJ"}
              type="date"
            />
          </div>
          <div className="col-md-4">
            <Customselect
              label={"Reporting Manager"}
              options={props?.reportingManager}
              value={selectedReportingManager}
              onChange={(e) => setSelectedReportingManager(e.target.value)}
            />
          </div>
          <div className="col-md-4">
            <Customselect
              multiSelect={true}
              label={"Category"}
              options={props?.categoryList}
              value={selectedCategory}
              defaultValue={selectedCategory}
              onChange={handleCategory}
            />
          </div>
          <div className="col-md-4">
            <Customselect
              multiSelect={true}
              label={"Sub Category"}
              options={props?.subCategoryList}
              value={selectedSubCategory}   
              defaultValue={selectedSubCategory}
              onChange={handleSubCategory}
            />
          </div>

          <div className="col-md-4 check-box">
            <Checkbox
              icon={<Icon.FiCheck color="#dc3545" size={20} />}
              name="my-input"
              checked={ischecked}
              onChange={(e) => setIsChecked(!ischecked)}
              borderColor={ischecked ? '#dc3545' : "#cccccc"}
              style={{ cursor: "pointer", width: "25px", height: "25px" }}
              labelStyle={{ marginLeft: 5, userSelect: "none" }}
              label="Interviewer"
            />
          </div>

        </div>
        <div className="text-center mt-3">
          <CustomButton
            styles={{ width: 120, height: 38, fontSize: 14 }}
            onClick={() => {
              addUser();
            }}
            children={"Submit"}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddUserModal;
